import React from 'react';

import { useOnboardingStore } from 'src/onboarding/store/onboarding.store';
import OnboardingAssistantAvatar from './avatar';
import OnboardingAssistantSpeechBubble from './speech-bubble';

const OnboardingAssistant = () => {
  const assistantText = useOnboardingStore((state) => state.assistantText);

  // if the assistant has nothing to say, hide it
  if (!assistantText) return null;

  return (
    <div className='flex flex-row space-x-2 w-full items-center'>
      <OnboardingAssistantAvatar />
      <OnboardingAssistantSpeechBubble assistantText={assistantText} />
    </div>
  );
};

export default OnboardingAssistant;
