import classNames from 'classnames';
import React from 'react';
import BubbleLeftRegularIcon from 'src/monet/icons/BubbleLeftRegularIcon';
import { useOnboardingStore } from 'src/onboarding/store/onboarding.store';

interface Props {
  assistantText: string;
}

const OnboardingAssistantSpeechBubble = ({ assistantText }: Props) => {
  const assistantColors = useOnboardingStore((state) => state.assistantColors);

  return (
    <div
      className={classNames(
        'relative left-2 rounded-2xl text-bedrock-serif-h2 py-[13px] px-4 mx-auto transition-colors',
        {
          'bg-bedrock-black text-bedrock-white': !assistantColors,
        }
      )}
      style={{
        ...(assistantColors && {
          color: assistantColors.text,
          backgroundColor: assistantColors.background,
        }),
      }}
    >
      <div
        className={classNames('absolute inset-y-0 -left-2 -z-10', {
          'text-bedrock-black': assistantColors === null,
        })}
        style={{ color: assistantColors?.background || '' }}
      >
        <BubbleLeftRegularIcon className='text-inherit' />
      </div>
      <div>{assistantText}</div>
    </div>
  );
};

export default OnboardingAssistantSpeechBubble;
