import React, { useEffect } from 'react';
import injectScript from 'src/utils/document/inject-script';

const useLoginSignupTrackers = () => {
  useEffect(() => {
    if (process.env.ENVIRONMENT === 'production') {
        injectScript(`${process.env.HTTP_PROTOCOL}://js.hs-scripts.com/20096716.js`, 'hs-script-loader').catch((e) => {});
    }
  }, []);
};

export default useLoginSignupTrackers;
