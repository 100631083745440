/* eslint-disable no-unused-vars */
// linter gives a false-positive for typescript-enums, needs to investigate

export enum NewJourneyType {
  SCRATCH = 'empty',
  NEUE_SCRATCH = 'neue-empty',
  WIZARD = 'wizard',
  ORG_TEMPLATE = 'org-template',
  JOURNEY_TEMPLATE = 'journey-template',
}
