import React from 'react';
import { useNewJourneyStore } from 'src/dashboard/store/new-journey';

import { Nullable } from 'src/types/nullable.type';
import TemplateListItem from './template-list-item';

interface Props {
  templates: any[];
  title: Nullable<string>;
}

const TemplateListCategory = ({ templates, title, ...rest }: Props) => {
  const journeyTemplate = useNewJourneyStore((state) => state.journeyTemplate);

  return (
    <div className='flex flex-col'>
      {templates.map((template, index) => {
        const isSelected = journeyTemplate && template.id === journeyTemplate.id;
        return <TemplateListItem key={index} template={template} index={index} isSelected={isSelected} />;
      })}
    </div>
  );
};

export default TemplateListCategory;
