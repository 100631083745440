import React, { useEffect, useRef, useState } from 'react';
import ReactCrop, { centerCrop, Crop, makeAspectCrop, PercentCrop, PixelCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

import { useDebouncedEffect } from 'src/common/hooks/use-debounced-effect.hook';
import { Nullable } from 'src/types/nullable.type';
import { canvasPreview } from './canvas-preview';

type Props = {
  imageData: any;
  onImageCropped: (cropData: any) => void;
};

function centerAspectCrop(mediaWidth: number, mediaHeight: number, aspect: number) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

const AvatarCrop = ({ imageData, onImageCropped }: Props) => {
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const imgRef = useRef<HTMLImageElement>(null);

  const [crop, setCrop] = useState<Crop>();
  const [completedCrop, setCompletedCrop] = useState<Nullable<PixelCrop>>(null);

  useDebouncedEffect(
    async () => {
      if (completedCrop?.width && completedCrop?.height && imgRef.current && previewCanvasRef.current) {
        // We use canvasPreview as it's much faster than imgPreview.
        await canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop);
        onImageCropped(previewCanvasRef.current?.toDataURL('image/png'));
      }
    },
    100,
    [completedCrop]
  );

  const onCropComplete = (crop: PixelCrop, percent: PercentCrop) => {
    setCompletedCrop(crop);
  };

  const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    const { width, height } = e.currentTarget;
    setCrop(centerAspectCrop(width, height, 1));
  };

  return (
    <div className='flex flex-col space-y-md'>
      <div className='text-bedrock-p-strong text-bedrock-black'>Crop photo</div>
      {Boolean(completedCrop) && (
        <canvas
          ref={previewCanvasRef}
          style={{
            display: 'none',
            border: '1px solid black',
            objectFit: 'contain',
            width: completedCrop?.width,
            height: completedCrop?.height,
          }}
        />
      )}
      <ReactCrop
        className='rounded-md !max-w-[240px] !max-h-[240px] self-start'
        crop={crop}
        onChange={(c) => setCrop(c)}
        onComplete={onCropComplete}
        aspect={1}
      >
        <img src={imageData} className='' alt='crop' ref={imgRef} onLoad={onImageLoad} />
      </ReactCrop>
    </div>
  );
};

export default AvatarCrop;
