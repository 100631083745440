import React, { useCallback, FC } from 'react';
import { useParams } from 'react-router-dom';

import { Nullable } from 'src/types/nullable.type';
import useOnboardingAssistant from '../hooks/onboarding-assistant.hook';
import { PageOnboardingOrganization, PageUserAvatar, PageOrganizationBrand, PageOnboardingPersona } from '.';
import { PageOnboardingPurpose } from './onboarding-purpose.page';

const onboardingStepAssistantTextMap: Record<string, Nullable<string>> = {
  organization: 'Your organization',
  brand: 'Your brand',
  avatar: 'Your avatar',
  persona: 'Your role?',
  helpful: 'Where can Journey be most helpful?'
};

export const PageOnboarding: FC = () => {
  const { step } = useParams();
  useOnboardingAssistant(onboardingStepAssistantTextMap[step || 'persona']);

  const renderOnboardingComponent = useCallback(() => {
    switch (step) {
      case 'organization':
        return <PageOnboardingOrganization />;
      case 'brand':
        return <PageOrganizationBrand />;
      case 'avatar':
        return <PageUserAvatar />;
      case 'persona':
        return <PageOnboardingPersona />;
      case 'purpose': // Add case for new step
        return <PageOnboardingPurpose />;
      default:
        return <PageOnboardingPersona />;
    }
  }, [step]);

  return (
    <div className='flex flex-col space-y-4'>
      <div className='w-full m-auto'>
        <div className='mt-6 space-y-6'>{renderOnboardingComponent()}</div>
      </div>
    </div>
  );
};
