import React from 'react';

import { Nullable } from 'src/types/nullable.type';
import LoadingSpinner from 'src/common/components/loading-spinner';
import TemplateListCategory from './template-list-category';
import { NeueJourneyButton } from 'src/editor/content-creation/components/neue-button/journey';
import { NeueLoadingSpinner } from 'src/editor/content-creation/components/loading/spinner';

interface Props {
  onUseTemplate: () => void;
  createInProgress: boolean;
  categories: Nullable<any[]>;
}

const TemplateList = ({ onUseTemplate, createInProgress = false, categories }: Props) => {
  return (
    <>
      <div className='flex-1 overflow-y-auto'>
        {categories ? (
          categories.length ? (
            categories.map((category, index) => {
              return <TemplateListCategory key={index} templates={category.templates} title={category.name} />;
            })
          ) : (
            <span className='text-bedrock-black text-bedrock-p-strong text-bedrock-p-small'>No Templates Found</span>
          )
        ) : (
          <LoadingSpinner />
        )}
      </div>
      {categories && categories.length > 0 ? (
        <NeueJourneyButton type='button' className='w-full h-10' onClick={onUseTemplate} disabled={createInProgress} tabIndex={0}>
          {
            createInProgress ? <NeueLoadingSpinner theme='journey' size='16' /> : 'Start your Journey'
          }
        </NeueJourneyButton>
      ) : null}
    </>
  );
};

export default TemplateList;
