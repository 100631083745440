import React, { useMemo } from 'react';
import * as Yup from 'yup';
import delay from 'lodash/delay';
import classNames from 'classnames';
import objectPath from 'object-path';
import { Nullable } from 'src/types/nullable.type';

import { useNotificationMessage } from 'src/common/notification/message.hook';
import { apiUpdateOrganization, apiCreateOrganisation } from 'src/utils/journeyApi';
import { FormikTextInput } from 'src/common/form/formik/text-input';
import { JourneyForm, JourneyFormik } from 'src/common/JourneyFormik';
import { Organization } from 'src/common/interfaces/organization.interface';
import { Button } from 'src/common/button';
import { FormikProps } from 'formik';
import { useCurrentUser } from 'src/store/user';

const SUBMIT_DELAY = 600;

interface OrganizationFormProps {
  currentOrganization?: Nullable<Organization>;
  submitLabel: string;
  onSubmit: (response: Organization) => void;
  showSlug?: boolean;
  formClassName?: string;
  showGuidance?: boolean;
  stretchSaveButton?: boolean;
  allowSubmitWhenNotDirty?: boolean;
}

export const OrganizationForm = ({
  currentOrganization,
  submitLabel,
  onSubmit,
  stretchSaveButton = false,
  showSlug = true,
  formClassName = '',
  showGuidance = false,
  allowSubmitWhenNotDirty = false,
}: OrganizationFormProps) => {
  const userOrganizationId = useCurrentUser((state) => state.currentUser?.organization.id);
  const { setErrorNotification } = useNotificationMessage();

  const validationSchema = useMemo(
    () =>
      Yup.object({
        organization_name: Yup.string(),
        organization_url: Yup.string(),
        organization_slug: Yup.string().min(3, 'Must be at least 3 characters'),
      }),
    []
  );

  const getSubdomainPlaceholder = () => {
    if (currentOrganization?.url) {
      try {
        const url = new URL(currentOrganization.url);
        const hostname = url.hostname;
        // replace if it begins with www
        const trimmed_hostname = hostname.replace(/^www\./, '');

        return `custom-domain.${trimmed_hostname}`;
      } catch (err) {
        return '';
      }
    }
    return '';
  };

  const formikParams = {
    initialValues: {
      organization_name: currentOrganization?.name || '',
      organization_url: currentOrganization?.url || '',
      organization_slug: showSlug ? currentOrganization?.slug || '' : '',
      custom_domain: currentOrganization?.custom_domain || '',
    },
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema,
    onSubmit: async (values: any, { setSubmitting }: any) => {
      try {
        const params = {
          name: values.organization_name,
          slug: values.organization_slug,
          url: values.organization_url,
        };
        let response;
        if (currentOrganization) {
          response = await apiUpdateOrganization(currentOrganization.id, params);
        } else {
          response = await apiCreateOrganisation({ ...params, rootOrgId: userOrganizationId });
        }

        onSubmit(response);
      } catch (err: any) {
        setErrorNotification(err.message);
      } finally {
        delay(() => {
          setSubmitting(false);
        }, SUBMIT_DELAY);
      }
    },
  };

  return (
    <div className='flex flex-row space-x-xl'>
      <JourneyFormik {...formikParams}>
        {({ values, isSubmitting, dirty }: FormikProps<Record<string, string>>) => {
          const isFormValid = validationSchema.isValidSync(values);
          return (
            <JourneyForm className={classNames('flex flex-col space-y-4', formClassName)}>
              <FormikTextInput
                id='organization-name'
                name='organization_name'
                required={true}
                type='text'
                placeholder='Acme Corp'
                label='Organization name'
                autoFocus={true}
                errorClasses='mt-1'
              />
              <FormikTextInput
                id='organization-url'
                name='organization_url'
                required
                type='text'
                placeholder='https://acme.com'
                label='Organization URL'
                errorClasses='mt-1'
              />
              <div className='relative'>
                <FormikTextInput
                  required
                  id='organization-slug'
                  name='organization_slug'
                  type='text'
                  placeholder='acme'
                  label='Organization subdomain'
                  inputClasses='mr-[80px]'
                  errorClasses='mt-1'
                />
                <div className='absolute right-0 bottom-3 text-bedrock-p text-bedrock-black'>.journey.io</div>
              </div>
              <div className='relative'>
                <FormikTextInput
                  id='organization-custom_domain'
                  name='custom_domain'
                  type='text'
                  placeholder={getSubdomainPlaceholder()}
                  disabled={true}
                  label='Custom domain (Teams only)'
                  errorClasses='mt-1'
                />
                <div className='text-bedrock-p-small mt-1'>Reach out to founders@journey.io to set this up</div>
              </div>
              <div className='flex !mt-6'>
                <Button
                  type='submit'
                  variant='primary'
                  size='regular'
                  className={stretchSaveButton ? 'grow' : ''}
                  disabled={!isFormValid || (!allowSubmitWhenNotDirty && !dirty) || isSubmitting}
                  label={submitLabel}
                />
              </div>
            </JourneyForm>
          );
        }}
      </JourneyFormik>
      {showGuidance && (
        <div className='mt-[30px] pl-lg h-[230px] border-l border-bedrock-gray-medium'>
          <div className='flex items-center h-full'>
            <div className='flex text-bedrock-p text-bedrock-black max-w-[320px]'>
              When recipients of your Journeys click on the logo they will be taken to this URL
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
