import React from 'react';
import { Redirect, Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { LocationChangeListener } from 'src/common/location-change-listener';

import EmbedTokenExchange from 'src/dashboard/EmbedTokenExchange';
import OnboardingLayout from './components/layout/onboarding-layout';

import {
  PageForgotPassword,
  PageLogin,
  PageOnboarding,
  PageResetPassword,
  PageSignup,
  CommentsUnsubscribe,
} from './pages';
import PageNewJourneyTemplate from 'src/dashboard/pages/new-journey-template.page';

const OnboardingRouter = () => {
  return (
    <Router>
      <LocationChangeListener />
      <Switch>
        <Route path='/login'>
          <OnboardingLayout fixedTopMargin>
            <PageLogin />
          </OnboardingLayout>
        </Route>
        <Route path='/forgot-password'>
          <OnboardingLayout>
            <PageForgotPassword />
          </OnboardingLayout>
        </Route>
        <Route path='/reset-password'>
          <OnboardingLayout>
            <PageResetPassword />
          </OnboardingLayout>
        </Route>
        <Route path='/signup'>
          <OnboardingLayout fixedTopMargin>
            <PageSignup />
          </OnboardingLayout>
        </Route>
        <Route path={'/token/:token'}>
          <OnboardingLayout fixedTopMargin>
            <EmbedTokenExchange />
          </OnboardingLayout>
        </Route>
        <Route path={`/onboarding/:step`}>
          <OnboardingLayout fixedTopMargin>
            <PageOnboarding />
          </OnboardingLayout>
        </Route>
        <Route path={`/comments-unsubscribe`}>
          <OnboardingLayout>
            <CommentsUnsubscribe />
          </OnboardingLayout>
        </Route>

        <Route path={`/templates`}>
          <PageNewJourneyTemplate />
        </Route>        
        <Redirect from={'/onboarding'} to={'/onboarding/persona'} />
      </Switch>
    </Router>
  );
};

export default OnboardingRouter;
