import React, { forwardRef } from 'react';

import FileDropzone from 'src/common/FileDropzone';
import { Nullable } from 'src/types/nullable.type';

interface Props {
  setImageSource: (imageSource: string) => void;
}

const AvatarUpload = forwardRef<Nullable<HTMLInputElement>, Props>(({ setImageSource }, uploadRef) => {
  /**
   * prepare selected file for crop
   * @param files The file that got selected
   */
  const onSelectFile = (files: Array<File>) => {
    if (files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        setImageSource((reader.result && reader.result.toString()) || '');
      });
      reader.readAsDataURL(files[0]);
    }
  };

  return (
    <div className='hidden'>
      <FileDropzone
        accept='image/*'
        type='file'
        className='file-dropzone'
        multiple={false}
        onFilesUpdate={(files: Array<File>) => onSelectFile(files)}
        resetOnSuccess={true}
        dropzoneClasses={null}
        inputRef={uploadRef}
      />
    </div>
  );
});

export default AvatarUpload;
