import React, { FC } from 'react';
import classNames from 'classnames';

interface OnboardingFormLayoutProps {
  className?: any;
}

const OnboardingFormLayout: FC<OnboardingFormLayoutProps> = ({ className, children }) => {
  return <div className={classNames('max-w-[304px]', className)}>{children}</div>;
};

export default OnboardingFormLayout;
