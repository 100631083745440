import React, { useEffect, useState } from 'react';

import { NotificationMessage } from 'src/common/notification/message';

import { apiGetCurrentUser } from './utils/journeyApi';
import { useCurrentOrganization } from './store/organization';
import { useCurrentUser } from './store/user';
import OnboardingRouter from './onboarding/onboarding.router';
import LoadingSpinner, { FixedLoadingSpinner } from 'src/common/components/loading-spinner';

function LoginApp() {
  const [isAppLoadComplete, setIsAppLoadComplete] = useState(false);

  const setCurrentOrganization = useCurrentOrganization((state) => state.setCurrentOrganization);
  const setCurrentUser = useCurrentUser((state) => state.setCurrentUser);

  useEffect(() => {
    // TODO hold logged in state in local storage to avoid unnnecessary API calls
    console.log("getting current user login app");
    apiGetCurrentUser()
      .then((user) => {
        setCurrentUser(user);
        setCurrentOrganization(user.last_organization);
        setIsAppLoadComplete(true);

        // initalizeThirdParty(user);
        // AnalyticsHelper.identifyUser(user);
      })
      .catch((err) => {
        // TODO - redirect user to login if not authenticated and not on login page
        setIsAppLoadComplete(true);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isAppLoadComplete) {
    return <FixedLoadingSpinner />;
  }

  return (
    <>
      <NotificationMessage />
      <OnboardingRouter />
    </>
  );
}

export default LoginApp;
