import React, { useMemo } from 'react';

import { AddStrongIcon } from 'src/monet/icons';
import { Nullable } from 'src/types/nullable.type';
import { generateInitials } from 'src/utils/text';
import { useCurrentUser } from 'src/store/user';
import { Button } from 'src/common/button';

interface Props {
  cropSrc: Nullable<string>;
  onUploadClicked: () => void;
}

const AvatarPreview = ({ cropSrc, onUploadClicked }: Props) => {
  const currentUser = useCurrentUser((state) => state.currentUser);
  const userInitials = useMemo(() => (currentUser ? generateInitials(currentUser?.name) : null), [currentUser]);

  const avatarUrl = useMemo(() => cropSrc || currentUser?.avatar_url || null, [cropSrc, currentUser?.avatar_url]);

  return (
    <div className='flex flex-row items-center space-x-4'>
      <div className='w-[112px] h-[112px] bg-bedrock-light-gray rounded-lg relative items-center justify-center'>
        <div className='w-full h-full flex items-center justify-center group cursor-pointer hover:opacity-80'>
          {avatarUrl ? (
            <img src={avatarUrl} className='w-12 h-12 block rounded-full' alt='user avatar' />
          ) : (
            <span className='w-12 h-12 flex flex-shrink-0 justify-center items-center bg-bedrock-dark-gray rounded-full text-bedrock-white'>
              {userInitials}
            </span>
          )}
        </div>
      </div>
      <UploadTextWithPlusIconButton onClick={onUploadClicked} />
    </div>
  );
};

const UploadTextWithPlusIconButton = ({ onClick }: { onClick: () => void }) => {
  return <Button size='regular' variant='tertiary' icon={<AddStrongIcon />} label='Upload' onClick={onClick} />;
};

export default AvatarPreview;
