import { create } from 'zustand';

import { Journey } from 'src/common/interfaces/journey.interface';
import { SLIDE_OVER_ANIMATION_DURATION_IN_MS } from 'src/store/slide-over';
import { Nullable } from 'src/types/nullable.type';
import { DashboardHomeTabType } from '../home/constants';

export interface DashboardState {
  activeTab: DashboardHomeTabType;
  templateJourney: Nullable<Journey>;
  isCreatingJourney: boolean;
  isCreatingJourneyFromTemplate: boolean;
  setActiveTab: (activeTab: DashboardHomeTabType) => void;
  setTemplateJourney: (journey: Nullable<Journey>) => void;
  setIsCreatingJourney: (isCreatingJourney: boolean) => void;
  setIsCreatingJourneyFromTemplate: (isCreatingJourneyFromTemplate: boolean) => void;
  onJourneyCreateTemplateOpen: (journey: Journey) => void;
  onJourneyCreateTemplateClose: () => void;
}

const useDashboardStore = create<DashboardState>((set, get) => ({
  activeTab: 'journeys',
  templateJourney: null,
  showJourneyTemplatePanel: false,
  showJourneySharePanel: false,
  isCreatingJourney: false,
  isCreatingJourneyFromTemplate: false,
  setActiveTab: (activeTab: DashboardHomeTabType) => set((state) => ({ ...state, activeTab })),
  setTemplateJourney: (journey: Nullable<Journey>) => set(() => ({ templateJourney: journey })),

  onJourneyCreateTemplateOpen: (journey: Journey) => {
    set({ templateJourney: journey });
  },
  onJourneyCreateTemplateClose: () => {
    setTimeout(() => {
      set({ templateJourney: null });
    }, SLIDE_OVER_ANIMATION_DURATION_IN_MS);
  },

  setIsCreatingJourney: (isCreatingJourney: boolean) => {
    set({ isCreatingJourney });
  },
  setIsCreatingJourneyFromTemplate: (isCreatingJourneyFromTemplate: boolean) => {
    set({ isCreatingJourneyFromTemplate });
  },
}));

export default useDashboardStore;
