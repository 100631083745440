import React, { LegacyRef, MutableRefObject, memo, useRef } from 'react';
import { useMeasure, useUpdateEffect } from 'react-use';
import { useNeueLayoutMode } from '../helpers/neue-layout-mode.hook';
import { usePlayerStore } from './player-store';
import { Nullable } from 'src/types/nullable.type';
import { NeueNavigationBar } from './neue-navigation-bar';
import { SpringConfig, useSpring, animated } from '@react-spring/web';
import { shallow } from 'zustand/shallow';
import { MemoizedNeuePlayerSectionBar } from './neue-player-section-bar';

const CONTENT_ENTER_ANIMATION_CONFIG: SpringConfig = {
  mass: 1,
  tension: 80,
  friction: 20,
  // duration: 600,
};

type Props = {
  loading: boolean;
  children: React.ReactNode;
  onToggleMapRef: MutableRefObject<(isOpened: boolean) => void>;
};

export const TemplateNeuePlayerContent = ({ loading, children, onToggleMapRef }: Props) => {
  const { setLayoutSizes, setCanvasScrollPosition, featureFlags } = usePlayerStore(
    (state) => ({
      setLayoutSizes: state.setLayoutSizes,
      setCanvasScrollPosition: state.setCanvasScrollPosition,
      featureFlags: state.featureFlags,
    }),
    shallow
  );
  const { isMobileLayout } = useNeueLayoutMode();

  const editorContentElementRef = useRef<Nullable<HTMLDivElement>>(null);

  const [innerLegacyRef, innerRect] = useMeasure();
  const innerRef = useRef<HTMLDivElement>();

  useUpdateEffect(() => {
    if (innerRect.width > 0) {
      const contentElement = editorContentElementRef.current!;
      const innerRect = innerRef.current!.getBoundingClientRect();
      const editorContentRect = contentElement.getBoundingClientRect();
      setLayoutSizes(
        {
          width: innerRect.width,
          height: editorContentRect.height, // Note, need this to be the height of the editorContent, not the outer area
        },
        contentElement
      );
    }
  }, [innerRect]);

  React.useEffect(() => {
    const contentElement = editorContentElementRef.current!;
    const handleScroll = () => {
      setCanvasScrollPosition(contentElement.scrollLeft, contentElement.scrollTop);
    };

    contentElement.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      contentElement.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const contentStyles = useSpring({
    opacity: loading ? 0 : 1,
    config: CONTENT_ENTER_ANIMATION_CONFIG,
  });

  const contentWrapper = <animated.div style={contentStyles}>{children}</animated.div>;

  if (isMobileLayout) {
    return (
      <div className='w-full h-full bg-neue-canvas-bg overflow-hidden relative'>
        <div
          className='w-full h-full overflow-x-hidden overflow-y-auto scrollbar-hidden flex flex-col px-4'
          ref={editorContentElementRef}
        >
          <div
            className='relative flex-1'
            ref={
              ((element: HTMLDivElement) => {
                innerRef.current = element;
                innerLegacyRef(element);
              }) as LegacyRef<HTMLDivElement>
            }
          >
            {contentWrapper}
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className='w-full h-full overflow-hidden relative px-16 pt-16' style={{
        backgroundColor: '#E8E8E8'
      }}>
        <div className='w-full h-full overflow-y-auto mx-auto rounded-lg bg-neue-canvas-bg' ref={editorContentElementRef}>
          <div
            className='relative mx-auto w-2/3 min-w-[720px] flex-1'
            ref={
              ((element: HTMLDivElement) => {
                innerRef.current = element;
                innerLegacyRef(element);
              }) as LegacyRef<HTMLDivElement>
            }
          >
            {contentWrapper}
          </div>
        </div>
      </div>
    );
  }
};
