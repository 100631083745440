import React, { FC, useCallback, useRef } from 'react';
import classNames from 'classnames';
import { Nullable } from 'src/types/nullable.type';

import { AddStrongIcon } from 'src/monet/icons';
import FileDropzone from 'src/common/FileDropzone';
import { Button } from 'src/common/button';

interface BrandLogoUploadProps {
  logoUrl: Nullable<string>;
  iconUrl: Nullable<string>;
  onLogoFileUpload: (file: File) => void;
  onIconFileUpload: (file: File) => void;
}

export const BrandLogoUpload: FC<BrandLogoUploadProps> = ({ logoUrl, iconUrl, onLogoFileUpload, onIconFileUpload }) => {
  const logoUploadRef = useRef<Nullable<HTMLElement>>(null);
  const iconUploadRef = useRef<Nullable<HTMLElement>>(null);

  const onLogoUploadClicked = () => {
    if (logoUploadRef.current) {
      logoUploadRef.current.click();
    }
  };

  const onIconUploadClicked = () => {
    if (iconUploadRef.current) {
      iconUploadRef.current.click();
    }
  };

  const onFilesUpdate = useCallback((files: Array<File>, onUpload) => {
    if (files.length > 0) {
      const file = files[0];
      onUpload && onUpload(file);
    }
  }, []);

  const renderFileUploader = useCallback(
    (inputRef, acceptType, uploadCallback) => {
      return (
        <FileDropzone
          accept={acceptType}
          type='file'
          className='file-dropzone'
          multiple={false}
          onFilesUpdate={(files: Array<File>) => onFilesUpdate(files, uploadCallback)}
          resetOnSuccess={true}
          dropzoneClasses={null}
          inputRef={inputRef}
        />
      );
    },
    [onFilesUpdate]
  );

  const renderImageWithUploadButton = useCallback(
    ({ url, imageSizeClasses }: { url: Nullable<string>; imageSizeClasses?: string }) => (
      <div className='w-full h-full relative group cursor-pointer'>
        <div className='absolute w-full h-full left-0 top-0 flex items-center justify-center transition-opacity opacity-100 group-hover:opacity-0'>
          {url && <img src={url} alt='personalization logo' className={classNames('block', imageSizeClasses)} />}
        </div>
        <div
          className={classNames(
            'absolute w-full h-full left-0 top-0 flex items-center justify-center transition-opacity',
            {
              'opacity-0 group-hover:opacity-100': url,
              'opacity-100': !url,
            }
          )}
        >
          <Button size='regular' variant='tertiary' icon={<AddStrongIcon />} label='Upload' />
        </div>
      </div>
    ),
    []
  );

  return (
    <div className='flex flex-col space-y-md'>
      <div className='text-bedrock-p-strong text-bedrock-black'>Logo</div>
      <div className='flex flex-col'>
        <div className='hidden'>
          {renderFileUploader(logoUploadRef, 'image/*', onLogoFileUpload)}
          {renderFileUploader(iconUploadRef, 'image/*', onIconFileUpload)}
        </div>
        <div
          className='flex relative w-[240px] h-[112px] bg-bedrock-light-gray rounded-lg'
          onClick={onLogoUploadClicked}
        >
          {renderImageWithUploadButton({
            url: logoUrl,
            imageSizeClasses: 'w-auto h-auto max-h-[44px] max-w-[166px]',
          })}
        </div>
        <div
          className='w-[112px] h-[112px] bg-bedrock-light-gray rounded-lg relative items-center justify-center cursor-pointer mt-md'
          onClick={onIconUploadClicked}
        >
          {renderImageWithUploadButton({
            url: iconUrl,
            imageSizeClasses: 'w-auto h-auto max-h-[50px] max-w-[50px]',
          })}
        </div>
      </div>
    </div>
  );
};
