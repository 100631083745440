import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'src/common/button';
import { useNotificationMessage } from 'src/common/notification/message.hook';
import { ResetPasswordForm } from '../components/forms/reset-password-form';
import OnboardingFormLayout from '../components/layout/onboarding-form-layout';
import useOnboardingAssistant from '../hooks/onboarding-assistant.hook';

export const PageResetPassword = () => {
  useOnboardingAssistant();
  const history = useHistory();
  const { setSuccessNotification } = useNotificationMessage();

  const onFormSubmitted = () => {
    setSuccessNotification('Password successfully updated');
    history.replace('/login');
  };

  const onToggleIntercomClicked = useCallback(() => {
    window.groove.widget.open();
  }, []);

  return (
    <div className='flex flex-col'>
      <h1 className='text-bedrock-serif-h1 antialiased'>Set new password</h1>
      <OnboardingFormLayout>
        <div className='flex flex-col space-y-4 mt-6'>
          <ResetPasswordForm onFormSubmit={onFormSubmitted} />
          <div className='flex space-x-4'>
            <Button variant='tertiary' size='regular' label='Contact support' onClick={onToggleIntercomClicked} />
          </div>
        </div>
      </OnboardingFormLayout>
    </div>
  );
};
