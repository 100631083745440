import React from 'react';
import { Referrer, useSignupStore } from 'src/onboarding/store/signup.store';
import { Nullable } from 'src/types/nullable.type';
import { useSegmentTrackedLink } from 'src/utils/segment-tracked-link.hook';

import googleLogo from '../../../logos/google.svg';
import microsoftLogo from '../../../logos/microsoft.svg';

export const urlToProviderSSO = (providerName: String, invite: Nullable<any>, referrer: Nullable<Referrer>, template_id: Nullable<string>, cancelInvite: Nullable<boolean | undefined> = false) => {
  const url = new URL(`/oauth/login/${providerName}`, window.location.origin);
  const searchParamas = url.searchParams;
  if (invite) {
    searchParamas.append('invite_token', invite.uuid);
  }
  if (referrer && referrer.slug) {
    searchParamas.append('referral', referrer.slug);
  }
  if (template_id) {
    searchParamas.append('template_id', template_id);
  }
  if(cancelInvite){
    searchParamas.append('cancel_invite', '1')
  }

  return url.href;
};


interface SignupProps {
  cancelInvite?: boolean;
}

const SignupForm : React.FC<SignupProps> = ({cancelInvite}) => {
  const { invite, referrer, template_id } = useSignupStore();

  // const isAlreadyUser = useMemo(() => {
  //   return invite && invite.already_is_user;
  // }, [invite]);

  const googleSignupLinkRef = useSegmentTrackedLink('Create Account Clicked', {
    provider: 'google',
  });
  const microsoftSignupLinkRef = useSegmentTrackedLink('Create Account Clicked', {
    provider: 'microsoft',
  });

  const renderForm = () => {
    return (
      <div>
        <div className='flex flex-col space-y-4 mt-8'>
          <a
            ref={googleSignupLinkRef}
            href={urlToProviderSSO('google', invite, referrer, template_id, cancelInvite)}
            className='btn-bedrock-secondary flex justify-center items-center space-x-2'
          >
            <img src={googleLogo} className='inline w-5 h-5' alt='Signup with Google' />
            <span>Create account with Google</span>
          </a>
          <a
            ref={microsoftSignupLinkRef}
            href={urlToProviderSSO('microsoft', invite, referrer, template_id, cancelInvite)}
            className='btn-bedrock-secondary flex justify-center items-center space-x-2'
          >
            <img src={microsoftLogo} className='inline w-5 h-5' alt='Signup with Microsoft' />
            <span>Create account with Microsoft</span>
          </a>
        </div>
        <div className='flex mt-4'>
          <div className='text-bedrock-black text-bedrock-p-small'>
            {`You agree to `}
            <a
              href='https://www.journey.io/terms'
              target='_blank'
              rel='noopener noreferrer'
              className='text-bedrock-dark-gray hover:text-bedrock-gray-medium'
            >
              Terms of Service
            </a>
            {` and `}
            <a
              href='https://www.journey.io/privacy-policy'
              target='_blank'
              rel='noopener noreferrer'
              className='text-bedrock-dark-gray hover:text-bedrock-gray-medium'
            >
              Privacy Policy
            </a>
            {` by creating an account`}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className='w-full'>
      {invite && (
        <div className='mt-6 text-bedrock-black text-bedrock-p'>
          {invite.created_by_user.name} invited you to the {invite.organization.name} organization on Journey.
        </div>
      )}
      <div className='mt-10 space-y-6'>{renderForm()}</div>
    </div>
  );
};

export default SignupForm;
