import React, { useState, useRef, useEffect } from 'react';

import { Nullable } from 'src/types/nullable.type';
import AvatarUpload from './avatar-upload';
import AvatarPreview from './avatar-preview';
import AvatarCrop from './avatar-crop';
import { EventBus } from 'src/common/EventBus';

interface Props {
  updateCrop: (updateCrop: string) => void;
}

/**
 * TODO: move this file to a separate component to be used in the user profile page
 * rename other avatar files to square image upload or something
 */
const UserAvatar = ({ updateCrop }: Props) => {
  // local data
  const [cropSrc, setCropSrc] = useState<Nullable<string>>(null);
  const [imgSrc, setImgSrc] = useState('');
  const uploadRef = useRef<Nullable<HTMLInputElement>>(null);

  useEffect(() => {
    EventBus.on('account-settings:user-updated', (user: any) => {
      setImgSrc('');
      setCropSrc(null);
    });

    return () => {
      EventBus.off('account-settings:user-updated');
    };
  }, [cropSrc]);

  const onUploadClicked = () => {
    if (uploadRef.current) {
      uploadRef.current.value = '';
      uploadRef.current.click();
    }
  };

  const onUpload = (imageSrc: string) => {
    setImgSrc(imageSrc);
  };

  const onImageCropped = (cropSrc: string) => {
    setCropSrc(cropSrc);
    updateCrop(cropSrc);
  };

  return (
    <>
      <div className='flex flex-col space-y-md'>
        <div className='text-bedrock-p-strong text-bedrock-black'>How you will appear</div>
        <div className='flex flex-col'>
          <AvatarUpload setImageSource={onUpload} ref={uploadRef} />
          <AvatarPreview cropSrc={cropSrc} onUploadClicked={onUploadClicked} />
        </div>
      </div>
      {imgSrc && <AvatarCrop imageData={imgSrc} onImageCropped={onImageCropped} />}
    </>
  );
};

export default UserAvatar;
