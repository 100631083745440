import React, { useRef } from 'react';
import classNames from 'classnames';
import { Nullable } from 'src/types/nullable.type';
import { useNeueLayoutMode } from '../helpers/neue-layout-mode.hook';
import { usePlayerStore } from './player-store';
import { promiseDelay } from 'src/common/helpers/promise-delay';
import { getHtmlText } from '../helpers/get-html-text';
import { useMutualActionPlansContext } from '../mutual-action-plans/store';
import { getJourneyThemeSettings } from '../helpers/get-journey-theme-settings';
import { NeueLoadingSpinner } from '../components/loading/spinner';
import { NeuePlayerContent } from './neue-player-content';
import { MemoizedNeuePlayerBlocksViewContainer } from './blocks-view-container';
import { Journey } from 'src/common/interfaces/journey.interface';
import { journeyToSections } from '../helpers/transforms/journey-to-sections';
import { createPropsEqualsChecker } from '../utils/create-props-equals-checker';
import { useScrollToSectionById } from './scroll-to-section-by-id.hook';
import { NeuePlayerTopBar } from './top-bar';
import { functionNoop } from 'src/utils/function/noop';
import { TemplateNeuePlayerContent } from './template-neue-player-content';

type Props = {
  journey: Nullable<Journey>;
  stepPath?: Nullable<string>;
};

export const NeueTemplatePlayerJourneyView = ({ journey }: Props) => {
  const initPlayer = usePlayerStore((state) => state.initPlayer);
  const initializeMutualActionPlanState = useMutualActionPlansContext((state) => state.initializeState);
  const resetPlayer = usePlayerStore((state) => state.resetPlayer);
  const setLayoutMode = usePlayerStore((state) => state.setLayoutMode);
  const [loadingJourney, setLoadingJourney] = React.useState(true);
  const { layoutMode } = useNeueLayoutMode();
  const layoutReady = usePlayerStore((state) => state.layout.layoutReady);
  const toggleMapCallbackRef = useRef(functionNoop);

  React.useLayoutEffect(() => {
    setLayoutMode(layoutMode);
  }, [layoutMode]);

  const isPlayerReady = !loadingJourney && !!journey && layoutReady;

  React.useEffect(() => {
    if (!journey) return;
    const initializeJourney = async () => {
      let sections = journeyToSections(journey, true);

      await initializeMutualActionPlanState(sections);

      const brandColor = journey?.branding?.secondary_color || null;
      const themeSettings = getJourneyThemeSettings(journey.theme || null, brandColor);
      const featureFlags = journey.feature_flags || {};
      initPlayer(journey.uuid, sections, themeSettings, featureFlags);
    };
    Promise.all([initializeJourney()]).then((_) => {
      setLoadingJourney(false);
    });
    return () => {
      if (journey) {
        resetPlayer();
      }
    };
  }, [journey]);

  const loading = !isPlayerReady;

  return (
    <div className='w-full h-full relative'>
      <div className={classNames('w-full h-full flex rounded-lg', { 'opacity-0': loading })}>
        <TemplateNeuePlayerContent onToggleMapRef={toggleMapCallbackRef} loading={loading}>
          <MemoizedNeuePlayerBlocksViewContainer />
        </TemplateNeuePlayerContent>
      </div>
      <div
        className={classNames('absolute inset-0 flex items-center justify-center', {
          'opacity-100': loading,
          'opacity-0 pointer-events-none': !loading,
        })}
      >
        <NeueLoadingSpinner size='32' theme='canvas' />
      </div>
    </div>
  );
};

export const MemoizedNeuePlayerJourneyView = React.memo(
  NeueTemplatePlayerJourneyView,
  createPropsEqualsChecker([], 'NeuePlayerJourneyView')
);
