import classNames from 'classnames';
import React, { FC, useCallback, useEffect, useMemo, useRef } from 'react';
import { getBrandingColors, getDefaultSourceColor } from 'src/brand-generation/colors';
import { Nullable } from 'src/types/nullable.type';
import { fetchUrlAsBlob } from 'src/utils/file';
import { formatUrl } from 'src/utils/url';
import { TitleAndDescription } from '../components/custom-metadata/title-description';
import { BrandBranding } from '../components/branding';
import { BrandFindBranding } from '../components/branding/find-branding';
import { BrandLogoUpload } from '../components/branding/logo-upload';
import { useBrandingFromBrandfetch } from '../hooks/use-branding-from-brandfetch.hook';
import { Organization } from '../interfaces/organization.interface';
import { JourneyForm } from '../JourneyFormik';
import { useNotificationMessage } from '../notification/message.hook';
import { BrandFormValues } from './constants';

type BrandFormContentProps = {
  values: BrandFormValues;
  setFieldValue: <T extends keyof BrandFormValues>(field: T, value: BrandFormValues[T]) => any;
  isSubmitting: boolean;
  dirty: boolean;
  onPrimaryColorUpdated?: (secondaryColor: string, brandTextColor: string) => void;
  showGuidance?: boolean;
  showFindBranding?: boolean;
  organization: Organization;
  actionButtonRenderer: (submitting: boolean, dirty: boolean) => React.ReactElement;
  notifyBrandfetchStates?: boolean;
  onBrandfetchSuccess?: () => void;
  onBrandfetchFetchingChange?: (fetching: boolean) => void;
  onBrandfetchError?: () => void;
  shouldFetchBranding?: boolean;
};

export const BrandFormContent: FC<BrandFormContentProps> = ({
  values,
  setFieldValue,
  isSubmitting,
  dirty,
  onPrimaryColorUpdated,
  showGuidance = true,
  showFindBranding = false,
  organization,
  actionButtonRenderer,
  notifyBrandfetchStates = true,
  onBrandfetchSuccess,
  onBrandfetchFetchingChange,
  onBrandfetchError,
  shouldFetchBranding = false,
}) => {

  const {
    fetchBrandfetchBranding,
    colorHex: brandfetchColorHex,
    logoUrlRef: brandfetchLogoUrlRef,
    iconUrlRef: brandfetchIconUrlRef,
    brandfetchStatus,
    fetching: isFetchingBrand,
  } = useBrandingFromBrandfetch();
  const { setSuccessNotification, setErrorNotification } = useNotificationMessage();

  const fetchBranding = useCallback(
    (url: Nullable<string> = null) => {
      url = url || organization.url;
      if (url) {
        const formattedUrl = formatUrl(url);
        const domain = new URL(formattedUrl).hostname;
        fetchBrandfetchBranding(domain);
      }
    },
    [organization.url]
  );

  useEffect(() => {
    if (shouldFetchBranding) {
      fetchBranding();
    }
  }, [shouldFetchBranding, fetchBranding]);

  const { logo_file, org_logo_url, icon_file, org_icon_url, secondary_color, custom_title, custom_meta_description } = values;
  const logoUrl = useMemo(() => {
    if (logo_file) {
      return URL.createObjectURL(logo_file);
    } else if (org_logo_url) {
      return org_logo_url;
    }

    return null;
  }, [logo_file, org_logo_url]);

  const iconUrl = useMemo(() => {
    if (icon_file) {
      return URL.createObjectURL(icon_file);
    } else if (org_icon_url) {
      return org_icon_url;
    }

    return null;
  }, [icon_file, org_icon_url]);

  const primaryColor = useMemo(() => {
    return secondary_color || getDefaultSourceColor();
  }, [secondary_color]);

  const broadcastRef = useRef(false);

  const onPrimaryColorChange = (primary: any) => {
    broadcastRef.current = true;
    setFieldValue('secondary_color', primary);
  };

  const brandingColors = useMemo(() => {
    const bc = getBrandingColors(primaryColor, false);
    if (onPrimaryColorUpdated && broadcastRef.current) {
      broadcastRef.current = false;
      onPrimaryColorUpdated(bc.brandSecondaryColor, bc.brandTextColor);
    }
    return bc;
  }, [onPrimaryColorUpdated, primaryColor]);

  const onUseDefaultColorClick = useCallback(() => {
    broadcastRef.current = true;
    setFieldValue('secondary_color', '');
  }, []);

  useEffect(() => {
    if (brandfetchColorHex) {
      onPrimaryColorChange(brandfetchColorHex);
    }
  }, [brandfetchColorHex]);

  const fetchBrandImages = async () => {
    let logoUrlImagePromise = brandfetchLogoUrlRef.current
      ? fetchUrlAsBlob(brandfetchLogoUrlRef.current)
      : Promise.resolve();
    let iconUrlImagePromise = brandfetchIconUrlRef.current
      ? fetchUrlAsBlob(brandfetchIconUrlRef.current)
      : Promise.resolve();
    try {
      onBrandfetchFetchingChange && onBrandfetchFetchingChange(true);
      const [logoUrlImageBlob, iconUrlImageBlob] = await Promise.all([logoUrlImagePromise, iconUrlImagePromise]);
      if (logoUrlImageBlob) {
        setFieldValue(
          'logo_file',
          new File([logoUrlImageBlob], 'logo.png', {
            type: logoUrlImageBlob.type,
          })
        );
      }
      if (iconUrlImageBlob) {
        setFieldValue(
          'icon_file',
          new File([iconUrlImageBlob], 'icon.png', {
            type: iconUrlImageBlob.type,
          })
        );
      }
    } catch (err) {
      //
    } finally {
      onBrandfetchFetchingChange && onBrandfetchFetchingChange(false);
    }
  };

  useEffect(() => {
    if (brandfetchStatus === 'success') {
      broadcastRef.current = true;
      notifyBrandfetchStates && setSuccessNotification('Branding generated successfully');
      onBrandfetchSuccess && onBrandfetchSuccess();
      fetchBrandImages();
    }
    if (brandfetchStatus === 'error') {
      broadcastRef.current = false;
      notifyBrandfetchStates && setErrorNotification('No branding found for this URL');
      onBrandfetchError && onBrandfetchError();
    }
  }, [brandfetchStatus]);

  useEffect(() => {
    onBrandfetchFetchingChange && onBrandfetchFetchingChange(isFetchingBrand);
  }, [isFetchingBrand]);

  const brandGuidance = useMemo(() => {
    const { colorGenerationOutputType } = brandingColors;
    if (colorGenerationOutputType === 'fallback_due_to_poor_generation_quality') {
      return (
        <div className='text-bedrock-p text-bedrock-red'>
          We don’t recommend using a color this light for call-to-actions, etc.
          <span
            className='text-bedrock-black underline decoration-bedrock-black ml-1 cursor-pointer hover:opacity-80'
            onClick={onUseDefaultColorClick}
          >
            Use default color
          </span>
        </div>
      );
    } else {
      return (
        <div className='flex text-bedrock-p text-bedrock-black'>
          We use your brand color and a second color created from your brand to color your CTA’s.
        </div>
      );
    }
  }, [brandingColors, onUseDefaultColorClick]);

  return (
    <JourneyForm className='flex flex-col gap-6'>
      <div className='flex flex-row'>
        <div className='flex flex-col gap-6 pr-xl'>
          <BrandLogoUpload
            logoUrl={logoUrl}
            iconUrl={iconUrl}
            onLogoFileUpload={(v: File) => setFieldValue('logo_file', v)}
            onIconFileUpload={(v: File) => setFieldValue('icon_file', v)}
          />
          <BrandBranding
            primaryColor={brandingColors.brandPrimaryColor}
            secondaryColor={brandingColors.brandSecondaryColor}
            onPrimaryColorChange={onPrimaryColorChange}
          />
          <TitleAndDescription
            onCustomTitle={(v: string) => setFieldValue('custom_title', v)}
            onCustomMetaDescription={(v: string) => setFieldValue('custom_meta_description', v)}
            titleValue={custom_title}
            metaDescriptionValue={custom_meta_description}
          />
          {showFindBranding && (
            <BrandFindBranding
              onFindBranding={(url: string) => fetchBranding(url)}
              currentOrganization={organization}
            />
          )}
        </div>
        {showGuidance && (
          <div
            className={classNames('mt-[38px] pl-lg border-l border-bedrock-gray-medium', {
              'h-[764px]': showFindBranding,
              'h-[590px]': !showFindBranding,
            })}
          >
            <div className='flex flex-col max-w-[320px]'>
              <div className='flex items-center h-[112px]'>
                <div className='flex text-bedrock-p text-bedrock-black'>
                  Your logo - Journey displays this prominently on the Journeys you create and share. We recommend
                  uploading a transparent png or svg.
                </div>
              </div>
              <div className='flex items-center mt-md h-[112px]'>
                <div className='flex text-bedrock-p text-bedrock-black'>
                  Occassionally when space is a concern, a minified icon version of your logo is shown.
                </div>
              </div>
              <div className='flex items-center h-[64px] mt-[62px]'>{brandGuidance}</div>
              <div className='flex items-center h-[64px] mt-[50px]'>
                <div className='flex text-bedrock-p text-bedrock-black'>
                  Enter a custom title. If none is provided, it will default to "A Journey from {organization.name}".
                </div>
              </div>
              <div className='flex items-center h-[64px] mt-[12px]'>
                <div className='flex text-bedrock-p text-bedrock-black'>
                  Please enter a meta description for your Journey. If left blank, it will default to "{organization.name} is telling their story with a Journey".
                </div>
              </div>
              {showFindBranding && (
                <div className='flex items-center h-[46px] mt-[112px]' >
                  <div className='flex text-bedrock-p text-bedrock-black'>
                    We’ll attempt to import a logo and color pallete from this URL.
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <div className='flex justify-start'>{actionButtonRenderer(isSubmitting, dirty)}</div>
    </JourneyForm>
  );
};
