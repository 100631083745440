import { FormikProps } from 'formik';
import React, { useMemo, useRef, useState, VFC } from 'react';
import { FormikTextInput } from 'src/common/form/formik/text-input';
import { JourneyFormik, JourneyForm } from 'src/common/JourneyFormik';
import * as Yup from 'yup';
import queryString from 'query-string';
import { apiResetPassword } from 'src/onboarding/api/auth.api';
import { useNotificationMessage } from 'src/common/notification/message.hook';
import { Button } from 'src/common/button';

interface ResetPasswordFormProps {
  onFormSubmit: () => void;
}

const validationSchema = Yup.object({
  password: Yup.string().required('required').min(12, 'length'),
});

export const ResetPasswordForm: VFC<ResetPasswordFormProps> = ({ onFormSubmit }) => {
  const [submitting, setSubmitting] = useState(false);
  const { setErrorNotification } = useNotificationMessage();

  const formikParams = useMemo(
    () => ({
      initialValues: { password: '' },
      validationSchema,
      validateOnBlur: false,
      validateOnChange: false,
      onSubmit: (values: Record<string, string>) => {
        setSubmitting(true);
        apiResetPassword({
          password: values.password,
          reset_token: (queryString.parse(window.location.search).reset_token as string) || '',
        })
          .then(() => {
            onFormSubmit();
          })
          .catch((error) => {
            setErrorNotification(error.message);
          })
          .finally(() => {
            setSubmitting(false);
          });
      },
    }),
    [onFormSubmit, setErrorNotification]
  );

  const errorColor = (validation: string, error?: string) => {
    if (!error) return 'text-bedrock-green';
    if (error === 'required') return 'text-bedrock-black';
    if (validation === 'length') {
      return error === 'match' || error === 'length' ? 'text-bedrock-black' : 'text-bedrock-green';
    } else {
      return error !== 'match' ? 'text-bedrock-green' : 'text-bedrock-black';
    }
  };

  return (
    <JourneyFormik {...formikParams}>
      {({ values, validateField, errors }: FormikProps<Record<string, string>>) => {
        const isFormValid = validationSchema.isValidSync(values);

        const onPasswordInputChanged = () => {
          validateField('password');
        };

        return (
          <JourneyForm className='flex flex-col space-y-4'>
            <div className='flex flex-col space-y-2'>
              <FormikTextInput
                required={true}
                id='password'
                name='password'
                label='Password'
                type='password'
                onChange={onPasswordInputChanged}
              />
              <div className='text-bedrock-p-small'>
                Use a minimum of <span className={errorColor('length', errors.password)}>12 characters</span> and
                <span className={errorColor('match', errors.password)}> a number or symbol</span>
              </div>
            </div>
            <Button
              className='h-10'
              type='submit'
              variant='primary'
              size='regular'
              disabled={!isFormValid || submitting}
              icon={
                submitting ? (
                  <svg
                    className='animate-spin h-4 w-4'
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                  >
                    <circle
                      className='opacity-25'
                      cx='12'
                      cy='12'
                      r='10'
                      stroke='currentColor'
                      strokeWidth='4'
                    ></circle>
                    <path
                      className='opacity-75'
                      fill='currentColor'
                      d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                    ></path>
                  </svg>
                ) : undefined
              }
              label={submitting ? '' : 'Save password'}
            />
          </JourneyForm>
        );
      }}
    </JourneyFormik>
  );
};
