import { create } from 'zustand';

import { NewJourneyType, } from 'src/common/interfaces/journey/new-journey-type.enum';
import { useCurrentOrganization } from 'src/store/organization';
import { Nullable } from 'src/types/nullable.type';
import { apiCreateBlankJourney, apiCreateNeueBlankJourney, apiCreateJourneyFromTemplate } from 'src/utils/journeyApi';
import { Journey } from 'src/common/interfaces/journey.interface';

export interface NewJourneyState {
  type: Nullable<NewJourneyType>;
  journeyWorkspace: any;
  journeyTemplate: Nullable<any>;
  journeyRecipient: Nullable<any>;
  setJourneyWorkspace: (journeyWorkspace: any) => void;
  setJourneyRecipient: (recipient: any) => void;
  setJourneyType: (type: NewJourneyType) => void;
  setJourneyTemplate: (template: any) => void;
  createJourney: (history?: any) => Promise<Journey>;
  createNeueJourney: (history: any) => void;
  resetTemplate: () => void;
  resetStore: () => void;
}

export const useNewJourneyStore = create<NewJourneyState>((set, get) => ({
  type: NewJourneyType.JOURNEY_TEMPLATE,
  journeyWorkspace: null,
  journeyTemplate: null,
  journeyRecipient: null,
  setJourneyRecipient: (recipient: any) => {
    set(() => ({
      journeyRecipient: recipient,
    }));
  },
  setJourneyType: (type: NewJourneyType) => {
    set(() => ({
      type,
    }));
  },
  setJourneyWorkspace: (journeyWorkspace: any) => {
    set(() => ({
      journeyWorkspace,
    }));
  },
  setJourneyTemplate: (template: any) => {
    set(() => ({
      journeyTemplate: template,
    }));
  },
  createJourney: async (history) => {
    const { journeyWorkspace, journeyTemplate, resetStore, journeyRecipient } = get();
    const { currentOrganization, currentWorkspace, teamWorkspace } = useCurrentOrganization.getState();
    if (!currentOrganization) {
      history.replace(`/signup?template_id=${journeyTemplate.id}`);
    } else {
      const organizationId = currentOrganization.id;
      // default ultimate fall back to team workspace
      const workspaceId = journeyWorkspace ? journeyWorkspace.id : currentWorkspace?.id || teamWorkspace?.id;

      const journey = await (journeyTemplate
        ? apiCreateJourneyFromTemplate(organizationId, workspaceId, journeyTemplate.id, journeyRecipient)
        : apiCreateBlankJourney(organizationId, workspaceId));

      if (history) {
        if (journey.is_neue) {
          history.replace(`/journeys/neue-edit/${journey.uuid}`);
        } else {
          history.replace(`/journeys/edit/${journey.uuid}`);
        }
        resetStore();
      }
      return journey;
    }
  },
  createNeueJourney: async ({
    history,
    name = '',
    generatedSlug = '',
    sellerDomain = '',
    recipientName = '',
    recipientDomain = '',
    recipientLogoUrl = '',
    journeyToDuplicate = null,
    precreatedSections = null,
    data = {},
  }: {
    history: any;
    name: string;
    generatedSlug: string;
    sellerDomain: string;
    recipientName: string;
    recipientDomain: string;
    recipientLogoUrl: string;
    journeyToDuplicate: Nullable<Pick<Journey, 'id' | 'uuid' | 'name'>>;
    precreatedSections: Nullable<any>;
    data?: any;
  }) => {
    const { journeyWorkspace, resetStore } = get();
    const { currentOrganization, currentWorkspace, teamWorkspace } = useCurrentOrganization.getState();
    const organizationId = currentOrganization.id;
    // default ultimate fall back to team workspace
    const workspaceId = journeyWorkspace ? journeyWorkspace.id : currentWorkspace?.id || teamWorkspace?.id;

    const journey: Journey = await apiCreateNeueBlankJourney(organizationId, workspaceId, {
      name: name,
      alias_slug: generatedSlug,
      duplicate_journey_uuid: journeyToDuplicate ? journeyToDuplicate.uuid : null,
      seller_domain: sellerDomain,
      recipient_domain: recipientDomain,
      recipient_logo_url: recipientLogoUrl,
      recipient_name: recipientName,
      precreated_sections: precreatedSections,
      data,
    });

    history.replace(`/journeys/neue-edit/${journey.uuid}`);
    resetStore();
  },
  resetTemplate: () => {
    set(() => ({
      type: null,
      journeyTemplate: null,
    }));
  },
  resetStore: () => {
    set(() => ({
      type: null,
      journeyWorkspace: null,
      journeyTemplate: null,
    }));
  },
}));
