import React from 'react';
import { useHistory } from 'react-router-dom';
import { OrganizationForm } from 'src/dashboard/forms/organization-form';
import OnboardingFormLayout from '../components/layout/onboarding-form-layout';

import { useCurrentOrganization } from 'src/store/organization';
import { Helmet } from 'react-helmet';
import { Organization } from 'src/common/interfaces/organization.interface';

export const PageOnboardingOrganization = () => {
  const history = useHistory();

  const currentOrganization = useCurrentOrganization((state) => state.currentOrganization);
  const setCurrentOrganization = useCurrentOrganization((state) => state.setCurrentOrganization);

  const onSubmit = (organization: Organization) => {
    setCurrentOrganization(organization);
    history.push('./brand');
  };

  return (
    <OnboardingFormLayout>
      <Helmet>
        <title>Journey | Your Organization</title>
      </Helmet>
      <OrganizationForm
        formClassName='w-full'
        currentOrganization={currentOrganization}
        submitLabel='Next'
        onSubmit={onSubmit}
        allowSubmitWhenNotDirty
        stretchSaveButton
      />
    </OnboardingFormLayout>
  );
};
