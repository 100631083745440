import omit from 'lodash/omit';

import { SentryHelper } from 'src/common/sentry';
import AnalyticsUtils from 'src/common/helpers/analyticsUtils';


const identifyHubUser = (user: any) => {
  var _hsq = (window._hsq = window._hsq || []);
_hsq.push([
  'identify',
  {
    name: user.name,
    email: user.email,
    last_organization: omit(user.last_organization),
    created_at: AnalyticsUtils.getTimeFromDate(user.created_at && user.created_at),
  }
]);
window._hsq = _hsq;

};

const trackHubPage = () => {
  var _hsq = (window._hsq = window._hsq || []);
  _hsq.push(['trackPageView']);
  window._hsq = _hsq;
};

const identifyCustomerUser = (user: any) => {
  const _cio = window._cio

  if(_cio){
    _cio.identify({
      id: user.email,
      name: user.name,
      email: user.email,
      last_organization: omit(user.last_organization),
      created_at: AnalyticsUtils.convertCustomerDate(user.created_at && user.created_at),
  });
  }


 };
 
 const trackCustomerPage = () => {
  const _cio = window._cio
  if(_cio){
    _cio.page();
  }

 };

const identifyUser = (user: any) => {
  // Segment identify

  let segErr=false;
  try{
    identifyHubUser(user)
    identifyCustomerUser(user)
  }
  catch(err: any){
    segErr=true
  }

  SentryHelper.setUser(user.email);
};

const trackPage = () => {
  // Segment page
  let segErr=false;
  try{
    trackHubPage()
    trackCustomerPage()
  }
  catch(err: any){
    segErr=true
  }
};

const AnalyticsHelper = {
  identifyUser,
  trackPage,
};

export default AnalyticsHelper;
