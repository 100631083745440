import React, { FC, useMemo, useState, ChangeEvent } from 'react';

import { TextInput } from 'src/common/form/text-input';
import { Textarea } from 'src/common/form/textarea';


interface TitleAndDescriptionProps {
  onCustomTitle: (custom_title: string) => void;
  onCustomMetaDescription: (custom_meta_description: string) => void;
  titleValue: string | undefined;
  metaDescriptionValue: string | undefined;
}

export const TitleAndDescription: FC<TitleAndDescriptionProps> = ({onCustomTitle, onCustomMetaDescription, titleValue, metaDescriptionValue}) => {
  const [title, setTitle] = useState(titleValue);
  const [description, setDescription] = useState(metaDescriptionValue);

  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
    onCustomTitle(event.target.value);
  };
  const handleDescriptionChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(event.target.value);
    onCustomMetaDescription(event.target.value);
  };

  return (
    <div className='flex flex-col space-y-md'>
      <div className='text-bedrock-p-strong text-bedrock-black'>Provide your own title and description to personalize your branding.</div>
      <div>
        <TextInput
          id='title'
          inputProps={{ value: title, placeholder: 'Custom title' }}
          onChange={handleTitleChange}
        />
        <Textarea
          containerClasses='flex-1 mt-4'
          inputClasses='h-24 pr-10'
          value={description}
          onChange={handleDescriptionChange}
          required={false}
          placeholder='Custom meta description'
          id='behavior-textarea'
          maxLength={300}
        />
      </div>
    </div>
  );
};
