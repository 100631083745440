import React, { useCallback, useMemo } from 'react';

import backgroundImg from 'src/images/onboarding.jpg';
import logo from 'src/images/journey-logo-new.svg';
import OnboardingAssistant from '../onboarding-assistant';
import { LeftStrongIcon } from 'src/monet/icons';
import { useHistory, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { IconButton } from 'src/common/icon-button';

// TODO - manage placement and width from store

interface OnboardingLayoutProps {
  children: React.ReactNode;
  fixedTopMargin?: boolean;
  fullSizeContent?: boolean;
}

const OnboardingLayout = ({ children, fixedTopMargin = false, fullSizeContent = false }: OnboardingLayoutProps) => {
  const onLogoClick = useCallback(() => {
    window.location.href = 'https://journey.io';
  }, []);

  const { show: showBackButton, onBack } = useShowOnboardingBackButton();

  return (
    <div className='h-screen'>
      <div className='flex h-full'>
        <div className=' flex-[2_2_0%]'>
          <div className='flex w-full p-10 items-start'>
            <img src={logo} alt='journey-logo' className='cursor-pointer' onClick={onLogoClick} />
          </div>
          <div className='grid grid-cols-12 pb-6 h-[calc(100vh-99px)]'>
            <div
              className={classNames('md:mx-0 mx-auto pb-xl', {
                'col-span-12': fullSizeContent,
                'col-start-2 col-span-10 xl:col-start-4 xl:col-span-8 md:col-span-6 md:col-start-4': !fullSizeContent,
                'my-[3px]': fixedTopMargin && !fullSizeContent && showBackButton,
                'my-[43px]': fixedTopMargin && !fullSizeContent && !showBackButton,
                'my-[calc(50vh-260px)]': !fixedTopMargin && !fullSizeContent,
              })}
            >
              <div className='w-full m-auto'>
                {showBackButton && (
                  <div className='mb-4'>
                    <IconButton onClick={onBack}>
                      <LeftStrongIcon />
                    </IconButton>
                  </div>
                )}
                <OnboardingAssistant />
                {children}
              </div>
            </div>
          </div>
        </div>
        <div className='relative flex-1 justify-end hidden md:block'>
          <div
            className='fixed inset-y-0 right-0 -z-10 w-1/3'
            style={{
              backgroundImage: `url(${backgroundImg})`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'right',
            }}
          />
          {/* <img src={backgroundImg} alt='' className='w-full h-screen' /> */}
        </div>
      </div>
    </div>
  );
};

const useShowOnboardingBackButton = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const show = useMemo(() => ['/onboarding/organization', '/onboarding/brand'].includes(pathname), [pathname]);
  const onBack = useCallback(() => {
    history.goBack();
  }, []);

  return { show, onBack };
};

export default OnboardingLayout;
