import classNames from 'classnames';
import { FormikProps } from 'formik';
import React, { useMemo, useRef, useState, VFC } from 'react';
import { Button } from 'src/common/button';
import { FormikTextInput } from 'src/common/form/formik/text-input';
import { JourneyFormik, JourneyForm } from 'src/common/JourneyFormik';
import { apiForgotPassword } from 'src/onboarding/api/auth.api';
import * as Yup from 'yup';

interface ForgotPasswordFormProps {
  onFormSubmit: () => void;
}

const validationSchema = Yup.object({ email: Yup.string().email('Invalid email address').required('Required') });

export const ForgotPasswordForm: VFC<ForgotPasswordFormProps> = ({ onFormSubmit }) => {
  const [submitting, setSubmitting] = useState(false);
  const submittedSuccessRef = useRef<boolean>(false);
  const errorMessageRef = useRef<string>('');

  const formikParams = useMemo(
    () => ({
      initialValues: { email: '' },
      validationSchema,
      onSubmit: (values: Record<string, string>) => {
        errorMessageRef.current = '';
        submittedSuccessRef.current = false;
        setSubmitting(true);
        apiForgotPassword(values)
          .then(() => {
            submittedSuccessRef.current = true;
          })
          .catch((error) => {
            errorMessageRef.current = error.message;
            submittedSuccessRef.current = false;
          })
          .then(() => {
            setSubmitting(false);
            if (errorMessageRef.current === '' && submittedSuccessRef.current) {
              onFormSubmit();
            }
          });
      },
    }),
    [onFormSubmit]
  );

  return (
    <JourneyFormik {...formikParams}>
      {({ values }: FormikProps<Record<string, string>>) => {
        const isFormValid = validationSchema.isValidSync(values);

        return (
          <JourneyForm className='flex flex-col space-y-4'>
            <FormikTextInput id='email' required name='email' type='email' label='Email address' />
            {errorMessageRef.current && <p className='mt-2 text-sm text-bedrock-red'>{errorMessageRef.current}</p>}
            <Button
              className='h-10'
              type='submit'
              variant='primary'
              size='regular'
              disabled={!isFormValid || submitting}
              icon={
                submitting ? (
                  <svg
                    className='animate-spin h-4 w-4'
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                  >
                    <circle
                      className='opacity-25'
                      cx='12'
                      cy='12'
                      r='10'
                      stroke='currentColor'
                      strokeWidth='4'
                    ></circle>
                    <path
                      className='opacity-75'
                      fill='currentColor'
                      d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                    ></path>
                  </svg>
                ) : undefined
              }
              label={submitting ? '' : 'Reset password'}
            />
          </JourneyForm>
        );
      }}
    </JourneyFormik>
  );
};
