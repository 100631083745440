import React, { FC, useRef, ReactElement } from 'react';
import { functionNoop } from 'src/utils/function/noop';
import { ChromePicker } from 'react-color';

import { JourneyMenu } from 'src/common/JourneyMenu';

interface BrandColorPickerMenuProps {
  hexColor: string;
  onChange: (hex: string) => void;
  renderOrigin: (onClick: any) => ReactElement;
}

const BrandColorPickerMenu: FC<BrandColorPickerMenuProps> = ({ hexColor, renderOrigin, onChange }) => {
  const openRef = useRef(functionNoop);
  const closeRef = useRef(functionNoop);

  const onPickerClick = () => {
    openRef.current();
  };

  return (
    <JourneyMenu
      openRef={openRef}
      closeRef={closeRef}
      placement='top-end'
      containerClassName='!p-0'
      renderOrigin={() => renderOrigin(onPickerClick)}
    >
      <ChromePicker key='branding-color-picker' disableAlpha color={hexColor} onChange={({ hex }) => onChange(hex)} />
    </JourneyMenu>
  );
};

export default BrandColorPickerMenu;
