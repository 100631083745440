import React, { useState, useEffect } from 'react';
import { apiCheckIfNdaSigned, apiUnlockJourneyByToken } from '../utils/journeyApi';
import { storeAccessedEmailForJourney } from 'src/utils/journeyAccess';
import JourneyCard from 'src/common/JourneyCard';
import JourneyExperienceOverlay from 'src/player/JourneyExperienceOverlay';
import { useParams } from 'react-router-dom';

const EmbedTokenExchange = () => {
  const [error, setError] = useState<any>(null);
  const [response, setResponse] = useState(null);
  const { token } = useParams();

  useEffect(() => {
    apiUnlockJourneyByToken(token)
      .then((res) => {
        const { email, alias_uuid, journey_url } = res;
        storeAccessedEmailForJourney(alias_uuid.replace(/-/g, ''), email);
        setResponse(res);
        window.location.href = journey_url;
      })
      .catch((e) => {
        console.log(e);
        setError(e);
      });
  }, [token]);

  if (!response && !error) return null;

  return (
    error && (
      <JourneyExperienceOverlay>
        <div className='flex items-center justify-center w-full h-full'>
          <div className='flex flex-col w-full xs:max-w-sm'>
            <JourneyCard className='p-4'>
              <div className='flex flex-col space-y-6 mt-2 text-center'>
                <div className='text-title'>Token Invalid</div>
                <div className='text-primary'>The token you have tried is invalid. Please try again.</div>
              </div>
            </JourneyCard>
          </div>
        </div>
      </JourneyExperienceOverlay>
    )
  );
};

export default EmbedTokenExchange;
