import React, { useState, useMemo, useCallback, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { useNotificationMessage } from 'src/common/notification/message.hook';
import { useCurrentUser } from 'src/store/user';
import { Nullable } from 'src/types/nullable.type';
import { apiUpdateUser } from 'src/utils/journeyApi';
import { uploadImageAssetToS3 } from 'src/common/helpers/file-upload';
import { useCurrentOrganization } from 'src/store/organization';
import { dataUrlToFile } from 'src/common/helpers/file';
import UserAvatar from 'src/common/components/user-avatar';
import { Helmet } from 'react-helmet';
import { Button } from 'src/common/button';

export const PageUserAvatar = () => {
  // hooks
  const history = useHistory();
  const { setErrorNotification } = useNotificationMessage();

  // store
  const currentOrganization = useCurrentOrganization((state) => state.currentOrganization);
  const setCurrentUser = useCurrentUser((state) => state.setCurrentUser);

  // local data
  const [submitting, setSubmitting] = useState(false);
  const [cropSrc, setCropSrc] = useState<Nullable<string>>(null);

  const nextStep = useCallback(() => {
    if (currentOrganization.is_onboarded) {
      // complete user onboarding
      window.location.replace('/');
    } else {
      history.push('./brand');
    }
  }, [currentOrganization.is_onboarded, history]);

  const onSubmit = useCallback(async () => {
    if (!cropSrc) {
      nextStep();
      return;
    }
    setSubmitting(true);

    const imageFile = await dataUrlToFile(cropSrc!, `${uuidv4().replace(/-/g, '').substring(0, 6)}.png`);

    try {
      const response = await uploadImageAssetToS3(imageFile);
      if (!response) throw new Error('Could not upload image');
      const { url } = response;

      const params: Record<string, any> = {
        avatar_url: url,
      };

      const updatedUser = await apiUpdateUser(params);
      if (updatedUser) {
        setSubmitting(false);
        setCurrentUser(updatedUser);
        nextStep();
      }
    } catch (error: any) {
      setSubmitting(false);
      setErrorNotification(error.message);
    }
  }, [cropSrc, nextStep, setCurrentUser, setErrorNotification]);

  return (
    <>
      <Helmet>
        <title>Journey | Your Avatar</title>
      </Helmet>
      <div className='flex flex-col items-start space-y-6'>
        <div className='max-w-[328px] text-bedrock-p text-bedrock-black'>
          When you create Journeys you’re able to add descriptions to and annotate your content. If you have a photo on
          LinkedIn, grab it from there.
        </div>
        <UserAvatar updateCrop={setCropSrc} />

        <div className='flex flex-row gap-2 w-[304px]'>
          <Button
            variant='secondary'
            size='regular'
            className='grow basis-1/2'
            onClick={nextStep}
            disabled={submitting}
            label='Do this later'
          />
          <Button
            variant='primary'
            size='regular'
            className='grow basis-1/2'
            onClick={onSubmit}
            disabled={submitting || !cropSrc}
            label='Next'
          />
        </div>
      </div>
    </>
  );
};

export default PageUserAvatar;
