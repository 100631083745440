import { FormikProps, FormikState } from 'formik';
import React, { FC } from 'react';
import { getBrandingColors } from 'src/brand-generation/colors';
import { useApplyBrandColors } from 'src/branding/set-brand-colors';
import { apiUpdateOrganizationBranding } from 'src/utils/journeyApi';
import { uploadLogoAssetToS3 } from '../helpers/file-upload';
import { Organization } from '../interfaces/organization.interface';
import { JourneyFormik } from '../JourneyFormik';
import { useNotificationMessage } from '../notification/message.hook';
import { BrandFormContent } from './brand-form-content';
import { BrandFormValues } from './constants';

function createInitalFormValues(organization: Organization): BrandFormValues {
  return {
    secondary_color: organization.branding?.secondary_color,
    org_logo_url: organization.branding?.logo_url,
    org_icon_url: organization.branding?.icon_url || undefined,
    logo_file: undefined,
    icon_file: undefined,
    custom_title: organization.branding?.custom_title || undefined,
    custom_meta_description: organization.branding?.custom_meta_description || undefined,
  };
}

type BrandFormikProps = {
  showGuidance?: boolean;
  showFindBranding?: boolean;
  shouldFetchBranding?: boolean;
  onSuccess?: () => void;
  organization: Organization;
  setOrganization: (organization: Organization) => void;
  onBrandfetchSuccess?: () => void;
  onBrandfetchFetchingChange?: (fetching: boolean) => void;
  onBrandfetchError?: () => void;
  notifyBrandfetchStates?: boolean;
  onPrimaryColorUpdated?: (secondaryColor: string, brandTextColor: string) => void;
  actionButtonRenderer: (submitting: boolean, dirty: boolean) => React.ReactElement;
};

export const BrandFormik: FC<BrandFormikProps> = ({
  showGuidance = true,
  showFindBranding = false,
  shouldFetchBranding = false,
  notifyBrandfetchStates = true,
  onBrandfetchSuccess,
  onBrandfetchFetchingChange,
  onBrandfetchError,
  onSuccess,
  organization,
  setOrganization,
  actionButtonRenderer,
  onPrimaryColorUpdated,
}) => {
  const { applyBrandColors } = useApplyBrandColors();
  const { setErrorNotification } = useNotificationMessage();

  const initialValues: BrandFormValues = createInitalFormValues(organization);

  const formikParams = {
    initialValues,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (
      values: BrandFormValues,
      {
        setSubmitting,
        resetForm,
      }: {
        setSubmitting: (isSubmitting: boolean) => void;
        resetForm: (nextState?: Partial<FormikState<BrandFormValues>>) => void;
      }
    ) => {
      try {
        let params: Record<string, any> = {
          secondary_color: values.secondary_color,
          logo_url: values.org_logo_url,
          icon_url: values.org_icon_url,
          custom_title: values.custom_title,
          custom_meta_description: values.custom_meta_description,
        };
        if (values.logo_file) {
          const logoAssetResponse = await uploadLogoAssetToS3(values.logo_file);
          if (logoAssetResponse) {
            const { url } = logoAssetResponse;
            params.logo_url = url;
          }
        }
        if (values.icon_file) {
          const iconAssetResponse = await uploadLogoAssetToS3(values.icon_file);
          if (iconAssetResponse) {
            const { url } = iconAssetResponse;
            params.icon_url = url;
          }
        }
        const updatedOrganization = await apiUpdateOrganizationBranding(organization.id, params);
        setOrganization(updatedOrganization);
        const brandingColors = getBrandingColors(
          updatedOrganization.branding?.secondary_color,
          updatedOrganization.branding?.force_generated_colors || false
        );
        applyBrandColors(
          brandingColors.brandPrimaryColor,
          brandingColors.brandSecondaryColor,
          brandingColors.brandTextColor,
          ''
        );
        onSuccess && onSuccess();
        resetForm({
          values: createInitalFormValues(updatedOrganization),
        });
      } catch (e: any) {
        setErrorNotification(e.message);
      } finally {
        setSubmitting(false);
      }
    },
  };

  return (
    <JourneyFormik {...formikParams}>
      {({ values, setFieldValue, isSubmitting, dirty }: FormikProps<BrandFormValues>) => {
        return (
          <BrandFormContent
            values={values}
            setFieldValue={setFieldValue}
            isSubmitting={isSubmitting}
            dirty={dirty}
            shouldFetchBranding={shouldFetchBranding}
            showGuidance={showGuidance}
            showFindBranding={showFindBranding}
            organization={organization}
            actionButtonRenderer={actionButtonRenderer}
            onBrandfetchSuccess={onBrandfetchSuccess}
            onBrandfetchFetchingChange={onBrandfetchFetchingChange}
            onBrandfetchError={onBrandfetchError}
            notifyBrandfetchStates={notifyBrandfetchStates}
            onPrimaryColorUpdated={onPrimaryColorUpdated}
          />
        );
      }}
    </JourneyFormik>
  );
};
