import React from 'react';

interface BillingNoticeProps {
  joinOrg: () => void;
  createOrg: () => void;
}

const BillingNotice: React.FC<BillingNoticeProps> = ({joinOrg, createOrg}) => {
  return (
    <div className='pt-8 text-bedrock-black text-bedrock-p'>
              <div className="bg-white border-t-4 border-teal-400 rounded-b text-teal-900 px-4 py-3 shadow-md" role="alert">
  <div className="flex">
    <div className="py-1"><svg className="fill-current h-4 w-4 text-teal-500 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/></svg></div>
    <div className='space-y-2'>
      <p className="text-sm">You are about to join an organization with a paid subscription.<br/>Your organization will be billed for the new user.</p>
    </div>
  </div>
</div>
    
      <br/>
      <p className='pt-2'>
        If you prefer, you can start a new organization with a new account or join the existing organization.
      </p>
      <div className='flex flex-col items-center justify-start space-y-3 pt-5'>
        <button onClick={()=>joinOrg()} className='btn-bedrock-primary'>
          Join existing organization
        </button>
        <button onClick={()=>createOrg()} className='btn-bedrock-secondary'>
          Create new organization
        </button>
      </div>
    </div>
  );
};

export default BillingNotice;