import React, { FC, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { apiUpdateUser } from 'src/utils/journeyApi';
import { useCurrentOrganization } from 'src/store/organization';
import { Button } from 'src/common/button';
import { useCurrentUser } from 'src/store/user';
import { apiSegmentTrackAnonymous } from 'src/utils/segment';
import { useNotificationMessage } from 'src/common/notification/message.hook';
import classNames from 'classnames'; // Import classNames for styling

const options = [
  'Sales prospecting',
  'Product training/activation',
  'Post-demo followup (mutual action plans)',
  'Founder docs',
  'Marketing presentations',
  'Case studies',
  'Sales intro decks',
];

const Checkbox: FC<{ option: string; checked: boolean; onChange: () => void }> = ({ option, checked, onChange }) => {
  return (
    <div className='flex items-center mb-2'>
      <input
        type="checkbox"
        checked={checked}
        onChange={onChange}
        className='form-checkbox h-4 w-4 text-bedrock-brand-primary border-gray-300 rounded focus:ring-bedrock-brand-primary'
      />
      <label className='ml-2 text-bedrock-black flex text-sm'>{option}</label>
    </div>
  );
};

export const PageOnboardingPurpose: FC = () => {
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const currentOrganization = useCurrentOrganization((state) => state.currentOrganization);
  const history = useHistory();
  const [submitting, setSubmitting] = useState(false);
  const setCurrentUser = useCurrentUser((state) => state.setCurrentUser);
  const { setErrorNotification } = useNotificationMessage();

  const toggleOption = (option: string) => {
    setSelectedOptions((prev) =>
      prev.includes(option) ? prev.filter((o) => o !== option) : [...prev, option]
    );
  };

  const nextStep = useCallback(() => {
    if (currentOrganization.is_onboarded) {
      // complete user onboarding
      window.location.replace('/');
    } else {
      history.push('./organization');
    }
  }, [currentOrganization.is_onboarded, history]);

  const onSubmit = async () => {
    try {
      setSubmitting(true);
      const params: Record<string, any> = {
        purpose: selectedOptions, // Send selected options as persona
      };

      const updatedUser = await apiUpdateUser(params);
      if (updatedUser) {
        setSubmitting(false);
        setCurrentUser(updatedUser);
        apiSegmentTrackAnonymous('Journey Purpose Selected', {
          // Add any necessary tracking data here
          purpose: JSON.stringify(selectedOptions)
        });
        nextStep();
      }
    } catch (error: any) {
      setSubmitting(false);
      setErrorNotification(error.message);
    }
  };

  // Determine if the submit button should be disabled
  const submitDisabled = (selectedOptions && selectedOptions.length === 0) || submitting;

  return (
    <div className='flex flex-col items-stretch gap-4 max-w-[304px]'>
      <div className='text-bedrock-p-strong text-bedrock-black'>Where can Journey be most helpful for you? (Select all that apply)</div>
      <div className='space-y-3 mt-3'>
        {options.map((option) => (
          <Checkbox
            key={option}
            option={option}
            checked={selectedOptions.includes(option)}
            onChange={() => toggleOption(option)}
          />
        ))}
      </div>
      <div className='flex pt-4'>
        <Button
          type='submit'
          variant='primary'
          size='regular'
          className='grow'
          onClick={onSubmit}
          disabled={submitDisabled}
          label='Next'
        />
      </div>
    </div>
  );
};