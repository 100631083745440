import React, { useState, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useNotificationMessage } from 'src/common/notification/message.hook';
import { useCurrentUser } from 'src/store/user';
import { apiGetUserPersonas, apiUpdateUser } from 'src/utils/journeyApi';
import { useCurrentOrganization } from 'src/store/organization';
import { Helmet } from 'react-helmet';
import { Button } from 'src/common/button';
import { RadioGroup } from 'src/common/form/radio-group';
import { TextInput } from 'src/common/form/text-input';
import { apiSegmentTrackAnonymous } from 'src/utils/segment';
import { FixedLoadingSpinner } from 'src/common/components/loading-spinner';

type UserPersona = {
  name: string;
  code: string;
};

const OTHER_PERSONA_CODE = 'other';

export const PageOnboardingPersona = () => {
  const history = useHistory();
  const { setErrorNotification } = useNotificationMessage();
  const currentOrganization = useCurrentOrganization((state) => state.currentOrganization);
  const setCurrentUser = useCurrentUser((state) => state.setCurrentUser);

  const [selectedPersona, setSelectedPersona] = useState<UserPersona['code'] | ''>('');
  const [loading, setLoading] = useState(true);
  const [personas, setPersonas] = useState<UserPersona[]>([]);
  const [submitting, setSubmitting] = useState(false);
  const [otherText, setOtherText] = useState('');

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const response = await apiGetUserPersonas();
        setPersonas(response.user_personas);
      } catch (error: any) {
        setErrorNotification(error.message);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  const nextStep = useCallback(() => {
    if (currentOrganization.is_onboarded) {
      // complete user onboarding
      window.location.replace('/');
    } else {
      history.push('./purpose');
    }
  }, [currentOrganization.is_onboarded, history]);

  const onSubmit = async () => {
    try {
      setSubmitting(true);
      const params: Record<string, any> = {
        persona: selectedPersona,
      };

      const updatedUser = await apiUpdateUser(params);
      if (updatedUser) {
        setSubmitting(false);
        setCurrentUser(updatedUser);
        apiSegmentTrackAnonymous('User Persona Selected', {
          persona_code: selectedPersona,
          persona_name: personas.find((p) => p.code === selectedPersona)?.name || '',
          persona_other_value: selectedPersona === OTHER_PERSONA_CODE ? otherText : '',
        });
        nextStep();
      }
    } catch (error: any) {
      setSubmitting(false);
      setErrorNotification(error.message);
    }
  };

  const submitDisabled =
    !selectedPersona || (selectedPersona === OTHER_PERSONA_CODE && !otherText.trim()) || submitting;

  return (
    <>
      <Helmet>
        <title>Journey | Your Role</title>
      </Helmet>
      {loading ? (
        <FixedLoadingSpinner />
      ) : (
        personas?.length > 0 && (
          <div className='flex flex-col items-stretch gap-4 max-w-[304px]'>
            <div className='text-bedrock-p-strong text-bedrock-black'>What role best describes you?</div>
            <div className='flex flex-col gap-2'>
              <RadioGroup
                name='persona'
                className='flex flex-col space-y-sm'
                itemClassName='flex gap-4 items-center'
                options={personas.map(({ name, code }) => ({ label: name, value: code }))}
                value={selectedPersona}
                onChange={(option) => setSelectedPersona(option.value)}
              />
              {selectedPersona.toLowerCase() === OTHER_PERSONA_CODE && (
                <TextInput
                  id='role_other'
                  onChange={(e: any) => setOtherText(e.target.value)}
                  autoFocus
                  inputProps={{ value: otherText }}
                />
              )}
            </div>
            <div className='flex'>
              <Button
                type='submit'
                variant='primary'
                size='regular'
                className='grow'
                onClick={onSubmit}
                disabled={submitDisabled}
                label='Next'
              />
            </div>
          </div>
        )
      )}
    </>
  );
};

export default PageOnboardingPersona;
