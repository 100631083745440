import React, { useState, useEffect, VFC, useMemo, useRef } from 'react';
import queryString from 'query-string';
import * as Yup from 'yup';
import { useLocation } from 'react-router-dom';
import { FormikProps } from 'formik';

import { JourneyForm, JourneyFormik } from 'src/common/JourneyFormik';
import { FormikTextInput } from 'src/common/form/formik/text-input';
import { apiLogin } from 'src/onboarding/api/auth.api';
import { Button } from 'src/common/button';

import { urlToProviderSSO } from './signup-form';
import googleLogo from '../../../logos/google.svg';
import microsoftLogo from '../../../logos/microsoft.svg';

interface LoginFormProps {
  onLoginSuccess: (response: Record<string, string>) => void;
}

const validationSchema = Yup.object({
  email: Yup.string().email('Invalid email address').required('Required'),
  password: Yup.string().required('Required'),
});

export const LoginForm: VFC<LoginFormProps> = ({ onLoginSuccess }) => {
  const [submitting, setSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const location = useLocation();

  const { next, template_id } = queryString.parse(location.search);

  const formikParams = useMemo(
    () => ({
      initialValues: { email: '', password: '' },
      validationSchema,
      onSubmit: (values: Record<string, string>) => {
        setSubmitting(true);
        setErrorMessage(null);
        const loginParams = { ...values, template_id };
        apiLogin(loginParams, next)
          .then(onLoginSuccess)
          .catch((response) => {
            setErrorMessage(response.message);
            setSubmitting(false);
          });
      },
    }),
    [next, onLoginSuccess]
  );

  return (
    <JourneyFormik {...formikParams}>
      {({ values }: FormikProps<Record<string, string>>) => {
        const isFormValid = validationSchema.isValidSync(values);

        return (
          <JourneyForm className='flex flex-col space-y-6'>
            <div className='flex flex-col space-y-4'>
              <FormikTextInput
                id='email'
                required
                name='email'
                type='email'
                label='Email address'
                errorClasses='mt-1'
              />
              <FormikTextInput
                id='password'
                required
                name='password'
                type='password'
                label='Password'
                errorClasses='mt-1'
              />
            </div>
            <div className='flex flex-col space-y-2'>
              {errorMessage && <div className='text-bedrock-red text-sm'>{errorMessage}</div>}
              <Button
                className='h-10'
                type='submit'
                variant='primary'
                size='regular'
                disabled={submitting}
                icon={
                  submitting ? (
                    <svg
                      className='animate-spin h-4 w-4'
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 24 24'
                    >
                      <circle
                        className='opacity-25'
                        cx='12'
                        cy='12'
                        r='10'
                        stroke='currentColor'
                        strokeWidth='4'
                      ></circle>
                      <path
                        className='opacity-75'
                        fill='currentColor'
                        d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                      ></path>
                    </svg>
                  ) : undefined
                }
                label={submitting ? '' : 'Sign in'}
              />
            </div>
            <div className='flex flex-col space-y-4 !mt-4'>
              <a
                href={urlToProviderSSO('google', null, null, template_id as string)}
                className='btn-bedrock-secondary flex justify-center items-center space-x-2'
              >
                <img src={googleLogo} className='inline w-5 h-5'  alt='Sign in with Google' />
                <span>Sign in with Google</span>
              </a>
              <a
                href={urlToProviderSSO('microsoft', null, null, template_id as string)}
                className='btn-bedrock-secondary flex justify-center items-center space-x-2'
              >
                <img src={microsoftLogo} className='inline w-5 h-5' alt='Sign in with Microsoft' />
                <span>Sign in with Microsoft</span>
              </a>
            </div>
          </JourneyForm>
        );
      }}
    </JourneyFormik>
  );
};

export default LoginForm;
