import React, { useCallback, useRef, useState } from 'react';
import { getBrandfetchBranding, getBrandfetchColors, getBrandfetchLogos } from 'src/utils/branding';
import { BrandfetchStatus } from '../interfaces/brand.interface';

export const useBrandingFromBrandfetch = () => {
  const [colorHex, setColorHex] = useState<string>('');
  const logoUrlRef = useRef<string>();
  const iconUrlRef = useRef<string>();
  const [brandfetchStatus, setBrandfetchStatus] = useState<BrandfetchStatus>();
  const [fetching, setFetching] = useState(false);

  const resetBrandingData = useCallback(() => {
    setColorHex('');
    logoUrlRef.current = undefined;
    iconUrlRef.current = undefined;
    setBrandfetchStatus(undefined);
  }, []);

  const fetchBrandfetchBranding = useCallback(async (domain: string) => {
    try {
      setFetching(true);
      resetBrandingData();

      const brandfetchResponse = await getBrandfetchBranding(domain);
      const color = getBrandfetchColors(brandfetchResponse)?.default;
      const logos = getBrandfetchLogos(brandfetchResponse);
      const defaultLogo = logos?.defaultLogo;
      const defaultIcon = logos?.defaultIcon;

      if (!color && !defaultLogo) {
        setBrandfetchStatus('error');
      } else {
        setColorHex(color?.hex);
        logoUrlRef.current = defaultLogo?.src;
        iconUrlRef.current = defaultIcon?.src;
        if (color?.hex || defaultLogo?.src || defaultIcon?.src) {
          setBrandfetchStatus('success');
        }
      }
    } catch (error) {
      setBrandfetchStatus('error');
    } finally {
      setFetching(false);
    }
  }, [resetBrandingData]);

  return {
    fetchBrandfetchBranding,
    resetBrandingData,
    colorHex,
    logoUrlRef,
    iconUrlRef,
    brandfetchStatus,
    fetching,
  };
};
