import { create } from 'zustand';

import { Nullable } from 'src/types/nullable.type';
import { apiSignup } from '../api/auth.api';

export type Referrer = {
  name?: string;
  slug?: string;
};

interface State {
  invite: Nullable<any>;
  setInvite: (invite: any) => void;
  referrer: Nullable<Referrer>;
  setReferrer: (referrer: Referrer) => void;
  template_id: Nullable<string>;
  setTemplateId: (template_id: string) => void;
  isReferralValid: boolean;
  setIsReferralValid: (valid: boolean) => void;
  signUp: (params: any) => void;
  errorMessage: Nullable<any>;
  setErrorMessage: (errorMessage: any) => void;
}

export const useSignupStore = create<State>((set, get) => ({
  invite: null,
  referrer: null,
  template_id: null,
  isReferralValid: false,
  setReferrer: (referrer: Referrer) => {
    set({
      referrer,
    });
  },
  setInvite: (invite: any) => {
    set({
      invite,
    });
  },
  setIsReferralValid: (valid: boolean) => {
    set({
      isReferralValid: valid,
    });
  },
  setTemplateId: (template_id: string) => {
    set({
      template_id,
    });
  },
  signUp: async (values: any) => {
    set({
      errorMessage: null,
    });
    const { invite, referrer, template_id, isReferralValid } = get();
    try {
      const response = await apiSignup({
        ...values,
        referral: referrer ? referrer.slug : null,
        ...(invite && { invite_token: invite.uuid }),
        ...(template_id && { template_id }),
      });
      const creds = response.data;
      window.location.replace(creds.redirect || '/');
    } catch (error: any) {
      const response = error.response;
      set({
        errorMessage: response.data.message,
      });
      // setSubmitting(false);
    }
  },
  errorMessage: null,
  setErrorMessage: (errorMessage: any) => {
    set({
      errorMessage,
    });
  },
}));
