import React from 'react';
import ReactDOM from 'react-dom';
import { bootstrap } from './bootstrap';
import LoginApp from './LoginApp';

bootstrap();
ReactDOM.render(
  <React.StrictMode>
    <LoginApp />
  </React.StrictMode>,
  document.getElementById('login-root')
);
