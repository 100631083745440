import React, { useEffect, useRef } from 'react';

export function useDebouncedEffect(callback: any, timeout: number, deps: any[] = []) {
  const clearFn = useRef<any>();
  useEffect(() => {
    const handler = setTimeout(() => {
      if (clearFn.current && typeof clearFn.current === 'function') {
        clearFn.current();
      }
      clearFn.current = callback();
    }, timeout);

    return () => {
      clearTimeout(handler);
    };
  }, [timeout, ...deps]);
}
