import React, { useMemo } from 'react';
import OnboardingFormLayout from '../components/layout/onboarding-form-layout';

export const CommentsUnsubscribe = () => {
  let renderContent;
  const queryParams = useMemo(() => new URLSearchParams(window.location.search), []);
  if (queryParams.get('type') === 'node') {
    renderContent = () => {
      queryParams.delete('node_uuid');
      queryParams.set('type', 'alias');
      const unsubscribeFromJourneyAliasUrl = '/comments-unsubscribe?' + queryParams.toString();
      return (
        <>
          <div className='flex text-bedrock-p text-bedrock-black flex-col space-y-4 mt-6'>
            You have successfully unsubscribed from further replies.
          </div>
          <div className='flex text-bedrock-p text-bedrock-black flex-col space-y-4 mt-6'>
            If you would like to unsubscribe from all replies accross Journey,
            <a className='text-bedrock-p-strong' href={unsubscribeFromJourneyAliasUrl}>
              click here
            </a>
          </div>
        </>
      );
    };
  } else if (queryParams.get('type') === 'alias') {
    renderContent = () => {
      return (
        <>
          <div className='flex flex-col text-bedrock-p text-bedrock-black space-y-4 mt-6'>
            You have successfully unsubscribed from all replies accross this Journey
          </div>
        </>
      );
    };
  } else {
    renderContent = () => {
      return (
        <>
          <div className='flex flex-col text-bedrock-p text-bedrock-black space-y-4 mt-6'>
            Unsubscribe link is broken
          </div>
        </>
      );
    };
  }
  return (
    <div>
      <div className='flex flex-col'>
        <h1 className='text-bedrock-serif-h1 antialiased'>Unsubscribed</h1>
        <OnboardingFormLayout>{renderContent()}</OnboardingFormLayout>
      </div>
    </div>
  );
};
