import React, { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useHistory } from 'react-router-dom';

import useQueryParams from 'src/common/hooks/useQueryParams';
import { trackSegmentEvent } from 'src/utils/segment';
import { useSegmentTrackedLink } from 'src/utils/segment-tracked-link.hook';
import { URLSearchParams } from 'url';
import { apiValidateInviteToken, apiValidateOrganizationBySlug, apiCheckOrgSubscription } from '../api/invite.api';
import SignupForm from '../components/forms/signup-form';
import OnboardingFormLayout from '../components/layout/onboarding-form-layout';
import useLoginSignupTrackers from '../hooks/login-signup-trackers.hook';
import useOnboardingAssistant from '../hooks/onboarding-assistant.hook';
import { useOnboardingStore } from '../store/onboarding.store';
import { useSignupStore } from '../store/signup.store';
import BillingNotice from '../components/billing/BillingNotice';  // Import the new component

const REFERRAL_RUV_ALLIANCE = 'ruv-alliance';

export const PageSignup = () => {
  const { referrer, invite, isReferralValid, setReferrer, setInvite, setIsReferralValid, setTemplateId } = useSignupStore();
  const [cancelInvite, setCancelInvite] = useState(false);
  const [signupDataLoaded, setSignupDataLoaded] = useState(false);
  const [showBillingNotice, setShowBillingNotice] = useState(false);  // State for billing notice
  const setAssistantAvatar = useOnboardingStore((state) => state.setAssistantAvatar);
  const params: URLSearchParams = useQueryParams();
  const setAssistantText = useOnboardingAssistant();

  const history = useHistory();

  useLoginSignupTrackers();

  const { token, referral, template_id } = useMemo(() => {
    let referral = params.get('referral');
    referral = referral && typeof referral === 'string' ? referral.toLowerCase() : '';
    if (referral === REFERRAL_RUV_ALLIANCE) referral = 'ruvalliance';
    return {
      token: params.get('invite_token'),
      referral,
      template_id: params.get('template_id'),
    };
  }, [params]);

  useEffect(() => {
    const fetchInvite = async (token: string) => {
      try {
        const response = await apiValidateInviteToken(token);
        setAssistantText('Welcome');
        setInvite(response);
        if (response.organization.branding?.icon_url) {
          setAssistantAvatar(response.organization.branding?.icon_url);
        }

        // Check the organization's subscription status
        const subscriptionStatus = await apiCheckOrgSubscription(response.organization.id);
        if (subscriptionStatus.isPaid) {
          setShowBillingNotice(true);
        }
      } catch (e) {
        setAssistantText('Start your Journey');
        console.log(e);
      } finally {
        setSignupDataLoaded(true);
      }
    };

    const fetchReferrer = async (referral: string) => {
      try {
        const referrer = await apiValidateOrganizationBySlug(referral);
        const { name, slug, branding } = referrer;
        setReferrer({ name, slug });
        if (branding && branding.icon_url) {
          setAssistantAvatar(branding.icon_url);
        }
        setAssistantText("You've been invited");
        setIsReferralValid(true);
      } catch (e) {
        setAssistantText('Start your Journey');
        setIsReferralValid(false);
      } finally {
        setSignupDataLoaded(true);
      }
    };

    if (token) {
      fetchInvite(token);
    } else if (referral) {
      fetchReferrer(referral);
    } else {
      setAssistantText('Start your Journey');
      setSignupDataLoaded(true);
    }

    if (template_id) {
      setTemplateId(template_id);
    }
  }, [token, referral, template_id, setInvite, setReferrer, setIsReferralValid, setAssistantText, setAssistantAvatar, setTemplateId]);

  const joinOrg = () => {
    setShowBillingNotice(false);
  }

  const createOrg = () => {
    setInvite(null)
    setShowBillingNotice(false);
    setCancelInvite(true)
  }

  if (!signupDataLoaded) return null;

  let loginLink = '/login';
  if (template_id) {
    loginLink = `/login?template_id=${template_id}`;
  }

  return (
    <OnboardingFormLayout>
      <Helmet>
        <title>Journey | Sign Up</title>
      </Helmet>
      {referrer && isReferralValid && (
        <div className='mt-6 text-bedrock-p text-bedrock-black'>
          {referrer.name || referrer.slug} has referred you to Journey - Create your account and organization.
        </div>
      )}
      {showBillingNotice ?    
      <BillingNotice joinOrg={()=>joinOrg()} createOrg={()=>createOrg()} />
        :
        <SignupForm cancelInvite={cancelInvite}/>
    }  
      <div className='flex items-center justify-start mt-10'>
        <Link
          to={loginLink}
          className='btn-bedrock-tertiary-strong'
          onClick={() => trackSegmentEvent('Org Sign In Clicked', {})}
        >
          Sign in to your organization
        </Link>
      </div>
    </OnboardingFormLayout>
  );
};