import classNames from 'classnames';
import React, { useCallback, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Button } from 'src/common/button';
import { BrandFormik } from 'src/common/forms/brand-formik';
import { useOnboardingStore } from 'src/onboarding/store/onboarding.store';
import { useCurrentOrganization } from 'src/store/organization';
import { apiAddOrganizationAction } from 'src/utils/journeyApi';
import LoadingSpinner from 'src/common/components/loading-spinner';

export const PageOrganizationBrand = () => {
  const currentOrganization = useCurrentOrganization((state) => state.currentOrganization);
  const setCurrentOrganization = useCurrentOrganization((state) => state.setCurrentOrganization);
  const [isBrandfetchSuccess, setIsBrandfetchSuccess] = useState(false);
  const [isBrandfetchFetching, setIsBrandfetchFetching] = useState(false);
  const [isBrandfetchError, setIsBrandfetchError] = useState(false);
  const brandfetchOperationRef = useRef(false);
  const [redirecting, setRedirecting] = useState(false);

  const { setAssistantColors } = useOnboardingStore();

  const onBrandfetchSuccess = useCallback(() => {
    setIsBrandfetchSuccess(true);
  }, []);

  const onBrandfetchError = useCallback(() => {
    setIsBrandfetchError(true);
  }, []);

  const skipBrandStep = useCallback(async () => {
    await apiAddOrganizationAction(currentOrganization.id, 'onboarding_completed');
    setRedirecting(true);
    setTimeout(() => {
      window.location.replace('/');
    }, 50);
  }, [currentOrganization.id]);

  const completeOnboarding = useCallback(async () => {
    await apiAddOrganizationAction(currentOrganization.id, 'onboarding_completed');
    setRedirecting(true);
    setTimeout(() => {
      window.location.replace('/');
    }, 50);
  }, [currentOrganization.id]);

  const onPrimaryColorUpdated = useCallback(
    (brandSecondaryColor, brandTextColor) => {
      if (brandfetchOperationRef.current || isBrandfetchSuccess) {
        setTimeout(() => setAssistantColors({ text: brandTextColor, background: brandSecondaryColor }), 100);
      }
      if (isBrandfetchError && !brandfetchOperationRef.current) {
        brandfetchOperationRef.current = true;
      }
    },
    [isBrandfetchError, isBrandfetchSuccess, setAssistantColors]
  );

  const actionButtonRenderer = useCallback(
    (submitting: boolean, dirty: boolean) => {
      return (
        <div className='flex flex-row space-x-2'>
          <Button
            variant='secondary'
            size='regular'
            className='w-[148px]'
            label='Do this later'
            onClick={skipBrandStep}
            disabled={submitting || redirecting}
          />
          <Button
            type='submit'
            variant='primary'
            size='regular'
            className='w-[148px]'
            label='Go to dashboard'
            disabled={submitting || redirecting}
          />
        </div>
      );
    },
    [skipBrandStep, redirecting]
  );

  return (
    <>
      <Helmet>
        <title>Journey | Your Brand</title>
      </Helmet>
      {isBrandfetchFetching && <LoadingSpinner containerClassName='pt-xl' />}
      <div
        className={classNames('flex-col items-start', {
          hidden: isBrandfetchFetching,
          flex: !isBrandfetchFetching,
        })}
      >
        <div className='max-w-[328px] text-bedrock-p text-bedrock-black mb-6'>
          Your brand is important to you - it’s important to us too. We use your brand to make your Journey look
          distinctly yours.{isBrandfetchSuccess && ` We’ve tried to do this on your behalf.`}
        </div>
        <BrandFormik
          organization={currentOrganization}
          setOrganization={setCurrentOrganization}
          actionButtonRenderer={actionButtonRenderer}
          onPrimaryColorUpdated={onPrimaryColorUpdated}
          onSuccess={completeOnboarding}
          shouldFetchBranding={true}
          onBrandfetchSuccess={onBrandfetchSuccess}
          onBrandfetchFetchingChange={setIsBrandfetchFetching}
          onBrandfetchError={onBrandfetchError}
          notifyBrandfetchStates={false}
        />
      </div>
    </>
  );
};
