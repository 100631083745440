import React, { FC, useCallback, useMemo, useRef, useState } from 'react';

import * as Yup from 'yup';
import { Organization } from 'src/common/interfaces/organization.interface';
import { Button } from 'src/common/button';
import { TextInput } from 'src/common/form/text-input';

interface BrandFindBrandingProps {
  currentOrganization: Organization;
  onFindBranding: (url: string) => void;
}

export const BrandFindBranding: FC<BrandFindBrandingProps> = ({ currentOrganization, onFindBranding }) => {
  const [url, setUrl] = useState(currentOrganization.url);

  const urlValidationSchema = useMemo(() => Yup.string().urlRelaxed().required('Required'), []);

  let errorText = '';
  let hasError = false;
  try {
    urlValidationSchema.validateSync(url);
  } catch (e: any) {
    const validationErr = e as Yup.ValidationError;
    errorText = validationErr.message;
    hasError = Boolean(errorText);
  }

  const onTextKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      !hasError && url && onFindBranding(url);
      e.preventDefault();
    }
  };

  return (
    <div className='flex flex-col space-y-md mb-[32px]'>
      <div className='text-bedrock-p-strong text-bedrock-black'>Find my branding automagically</div>
      <div>
        <TextInput
          id='url'
          inputProps={{ value: url, placeholder: 'Organization URL' }}
          error={hasError}
          errorText={errorText}
          onChange={(e: any) => setUrl(e.target.value)}
          onKeyDown={onTextKeyDown}
        />
        <div className='flex items-start mt-4'>
          <Button
            variant='secondary'
            size='regular'
            type='button'
            disabled={hasError}
            label='Find branding'
            onClick={() => url && onFindBranding(url)}
          />
        </div>
      </div>
    </div>
  );
};
