import React, { useEffect, useState } from 'react';

import { useNewJourneyStore } from 'src/dashboard/store/new-journey';
import { useApplyThemeAtRoot } from 'src/editor/content-creation/helpers/themes/apply-theme-at-root.hook';
import { usePlayerStore } from 'src/editor/content-creation/neue-player/player-store';
import { NeueTemplatePlayerJourneyView } from 'src/editor/content-creation/neue-player/template-player-view';

const TemplatePlayer = () => {
  const template = useNewJourneyStore((state) => state.journeyTemplate);
  const [player, setPlayer] = useState<any | null>(null);
  const themeSettings = usePlayerStore((state) => state.themeSettings);
  useApplyThemeAtRoot(themeSettings);

  useEffect(() => {
    if (template) {
      setPlayer(
        <NeueTemplatePlayerJourneyView
          journey={template.journey}
        />
      );
    }
  }, [template]);

  if (!player) return null;

  return player;
};

export default TemplatePlayer;
