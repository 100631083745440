import { create } from 'zustand';

import { Nullable } from 'src/types/nullable.type';

interface State {
  assistantText: Nullable<string>;
  setAssistantText: (invite: any) => void;
  assistantAvatar: Nullable<any>;
  setAssistantAvatar: (assistantAvatar: any) => void;
  assistantColors: any;
  setAssistantColors: (colors: any) => void;
  onboardingStep: Nullable<any>;
  setOnboardingStep: (onboardingStep: any) => void;
}

export const useOnboardingStore = create<State>((set, get) => ({
  assistantText: null,
  onboardingStep: null,
  assistantAvatar: null,
  assistantColors: null,
  setAssistantColors(colors: any) {
    set({ assistantColors: colors });
  },
  setOnboardingStep: (onboardingStep: any) => {
    set({
      onboardingStep,
    });
  },
  setAssistantText: (assistantText: string) => {
    set({
      assistantText,
    });
  },
  setAssistantAvatar: (assistantAvatar: any) => {
    set({
      assistantAvatar,
    });
  },
}));
