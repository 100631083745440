import React, { FC, useCallback } from 'react';
import { IconButton } from 'src/common/icon-button';
import { EditStrongIcon } from 'src/monet/icons';

import BrandColorPickerMenu from './color-picker-menu';

interface BrandBrandingProps {
  primaryColor: string;
  secondaryColor: string;
  onPrimaryColorChange: (hex: string) => void;
}

export const BrandBranding: FC<BrandBrandingProps> = ({ primaryColor, secondaryColor, onPrimaryColorChange }) => {
  const colorPickerMenuOrigin = useCallback(
    (onClick) => (
      <IconButton onClick={onClick}>
        <EditStrongIcon />
      </IconButton>
    ),
    [primaryColor]
  );

  return (
    <div className='flex flex-col space-y-md'>
      <div className='text-bedrock-p-strong text-bedrock-black'>Branding</div>
      <div className='flex relative w-[120px] h-[64px]'>
        <div className='absolute left-0 z-10'>
          <div
            className='w-16 h-16 rounded-full bedrock-branding-color-ring'
            style={{
              backgroundColor: primaryColor,
            }}
          >
            <div className='w-full h-full flex items-center justify-center text-white'>
              <BrandColorPickerMenu
                hexColor={primaryColor}
                renderOrigin={colorPickerMenuOrigin}
                onChange={onPrimaryColorChange}
              />
            </div>
          </div>
        </div>
        <div className='absolute left-[56px]'>
          <div
            className='w-16 h-16 rounded-full'
            style={{
              backgroundColor: secondaryColor,
            }}
          ></div>
        </div>
      </div>
    </div>
  );
};
