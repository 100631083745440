import React, { useEffect } from 'react';
import { Nullable } from 'src/types/nullable.type';
import { useOnboardingStore } from '../store/onboarding.store';

const useOnboardingAssistant = (message: Nullable<string> = null) => {
  const setAssistantText = useOnboardingStore((state) => state.setAssistantText);

  useEffect(() => {
    setAssistantText(message);

    return () => setAssistantText(null);
  }, [message, setAssistantText]);

  return setAssistantText;
};

export default useOnboardingAssistant;
