import React from 'react';
import classNames from 'classnames';

import { CheckmarkRegularIcon, CheckmarkStrongIcon } from 'src/monet/icons';
import { useNewJourneyStore } from 'src/dashboard/store/new-journey';

interface TemplateListItemProps {
  template: any;
  isSelected: boolean;
  index: number;
}

const TemplateListItem = ({ template, isSelected, index }: TemplateListItemProps) => {
  const setJourneyTemplate = useNewJourneyStore((state) => state.setJourneyTemplate);

  const rowClass = classNames(
    'group flex flex-row items-center text-neue-journey-medium cursor-pointer rounded',
    'hover:text-white transition-colors h-[40px] p-2', {
      'text-white bg-white/[.08]': isSelected,
      'text-white/[.64]': !isSelected,
    }
  );

  const onRowClick = () => {
    setJourneyTemplate(template);
  };

  return (
    <div className={rowClass} onClick={onRowClick}>
      <div className='flex-1'>
        <div>{template.name}</div>
      </div>
      <div className='h-6 w-6'>{isSelected && <CheckmarkStrongIcon />}</div>
    </div>
  );
};

export default TemplateListItem;
