import React, { useCallback, useMemo, useState } from 'react';
import queryString from 'query-string';
import { Link, useHistory } from 'react-router-dom';
import { ForgotPasswordForm } from '../components/forms/forgot-password-form';
import OnboardingFormLayout from '../components/layout/onboarding-form-layout';
import useOnboardingAssistant from '../hooks/onboarding-assistant.hook';
import { Button } from 'src/common/button';

export const PageForgotPassword = () => {
  const history = useHistory();

  useOnboardingAssistant();
  const [formIsSent, setFormIsSent] = useState(false);

  const isLinkExpired = useMemo(() => queryString.parse(window.location.search).expired, []);

  const onFormSubmitted = useCallback(() => {
    setFormIsSent(true);
  }, []);

  const onToggleIntercomClicked = useCallback(() => {
    window.location.href = 'mailto:support@journey.io';
  }, []);

  const onLoginClicked = useCallback(() => {
    history.push('/login');
  }, []);

  const renderContactSupportButton = useCallback(
    () => (
      <div className='flex justify-start'>
        <Button variant='tertiary' size='regular' onClick={onToggleIntercomClicked} label='Contact support' />
      </div>
    ),
    [onToggleIntercomClicked]
  );

  const renderSignInAnchor = useCallback(
    () => (
      <div className='flex justify-start'>
        <Button variant='tertiary' size='regular' onClick={onLoginClicked} label='Sign in' />
      </div>
    ),
    [onLoginClicked]
  );

  const getResetCopy = () => (isLinkExpired ? 'Link expired' : 'Forgot password');

  return (
    <div>
      {!formIsSent ? (
        <div className='flex flex-col'>
          <h1 className='text-bedrock-serif-h1 antialiased'>{getResetCopy()}</h1>
          {isLinkExpired && (
            <p className='text-bedrock-p mt-6'>
              The link you’re trying to access has expired - please request a new password below.
            </p>
          )}
          <OnboardingFormLayout>
            <div className='flex flex-col space-y-4 mt-6'>
              <ForgotPasswordForm onFormSubmit={onFormSubmitted} />
              {renderContactSupportButton()}
            </div>
          </OnboardingFormLayout>
        </div>
      ) : (
        <div className='col-start-4 col-span-6 my-auto flex flex-col space-y-6'>
          <h1 className='text-bedrock-serif-h1 antialiased'>Check your email</h1>
          <p className='text-bedrock-p text-bedrock-black'>
            We have sent password recovery instructions to your email address.
          </p>
          <div className='flex space-x-4'>
            {renderContactSupportButton()}
            {renderSignInAnchor()}
          </div>
        </div>
      )}
    </div>
  );
};
