import React, { useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useHistory } from 'react-router-dom';
import { Button } from 'src/common/button';
import LoginForm from '../components/forms/login-form';

import OnboardingFormLayout from '../components/layout/onboarding-form-layout';
import useLoginSignupTrackers from '../hooks/login-signup-trackers.hook';
import useOnboardingAssistant from '../hooks/onboarding-assistant.hook';

export const PageLogin = () => {
  const history = useHistory();

  useOnboardingAssistant('Welcome back');
  useLoginSignupTrackers();

  const onUserSignedIn = useCallback((response: Record<string, string>) => {
    window.location.replace(response.redirect || '/');
  }, []);

  const onForgotPasswordClick = useCallback(() => {
    history.push('/forgot-password');
  }, []);

  const onCreateAccountClick = useCallback(() => {
    history.push('/signup');
  }, []);

  return (
    <OnboardingFormLayout>
      <Helmet>
        <title>Journey | Login</title>
      </Helmet>
      <div className='flex flex-col space-y-4'>
        <div className='w-full'>
          <div className='mt-6 space-y-6'>
            <LoginForm onLoginSuccess={onUserSignedIn} />
          </div>
        </div>
        <div className='flex space-x-4'>
          <Button variant='tertiary' size='regular' label='Forgot password' onClick={onForgotPasswordClick} />
          <Button variant='tertiary' size='regular' label='Create account' onClick={onCreateAccountClick} />
        </div>
      </div>
    </OnboardingFormLayout>
  );
};
