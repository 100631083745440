import { create } from 'zustand';

export type SlideOverType = undefined | 'share' | 'template' | 'integration-api-key' | 'ownership';
export const SLIDE_OVER_ANIMATION_DURATION_IN_MS = 50;

interface State {
  slideOverType: SlideOverType;
  isSlideOverOpen: boolean;
  openSlideOver: (type: SlideOverType) => void;
  closeSlideOver: () => void;
}

export const useSlideOverStore = create<State>((set, get) => ({
  slideOverType: undefined,
  isSlideOverOpen: false,
  openSlideOver: (type: SlideOverType) => {
    set({ slideOverType: type });
    setTimeout(() => {
      set({ isSlideOverOpen: true });
    }, SLIDE_OVER_ANIMATION_DURATION_IN_MS);
  },
  closeSlideOver: () => {
    set({ isSlideOverOpen: false });
    setTimeout(() => {
      set({ slideOverType: undefined });
    }, SLIDE_OVER_ANIMATION_DURATION_IN_MS);
  },
}));
