import React from 'react';

import { useOnboardingStore } from 'src/onboarding/store/onboarding.store';
import MonetAvatar from 'src/images/monet.inline.png';

const OnboardingAssistantAvatar = () => {
  const avatarUrl = useOnboardingStore((state) => state.assistantAvatar);

  return (
    <div
      className='w-auto h-auto h-[56px] w-[56px] rounded-full bg-cover bg-center'
      style={{
        backgroundImage: `url(${avatarUrl || MonetAvatar})`,
      }}
    ></div>
  );
};

export default OnboardingAssistantAvatar;
