import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { IconButton } from 'src/common/icon-button';
import { CloseStrongIcon, LeftStrongIcon } from 'src/monet/icons';
import { useNewJourneyStore } from '../store/new-journey';
import TemplateList from '../components/template/template-list';
import { NewJourneyType } from 'src/common/interfaces/journey/new-journey-type.enum';
import useDashboardStore from '../store/dashboard';
import useQueryParams from 'src/common/hooks/useQueryParams';
import TemplatePlayer from '../components/template/template-player';
import { apiGetJourneyTemplateCategories } from 'src/utils/journeyApi';
import { Nullable } from 'src/types/nullable.type';

const PageNewJourneyTemplate = () => {
  const history = useHistory();

  const setIsCreatingJourney = useDashboardStore((state) => state.setIsCreatingJourney);
  const { setJourneyTemplate, newJourneyType, resetTemplate, createJourney } = useNewJourneyStore((state) => ({
    setJourneyTemplate: state.setJourneyTemplate,
    createJourney: state.createJourney,
    resetTemplate: state.resetTemplate,
    newJourneyType: state.type,
  }));

  const [createInProgress, setCreateInProgress] = useState(false);
  const [categories, setCategories] = useState<Nullable<any[]>>(null);

  useEffect(() => {
    const loadCategories = async () => {
      let cList: any[] = [];

      const { journey_template_categories } = await apiGetJourneyTemplateCategories();
      const categoriesWithTemplate = journey_template_categories.filter(
        (category: any) => !!category.templates.length
      );

      if (categoriesWithTemplate.length) cList = categoriesWithTemplate;

      if (cList.length) {
        setJourneyTemplate(cList[0].templates[0]);
      }

      setCategories(cList);
    };

    if (newJourneyType) {
      loadCategories();
    }
  }, [newJourneyType, setJourneyTemplate]);

  useEffect(() => {
    return () => {
      resetTemplate();
    };
  }, [resetTemplate]);

  const onCreateJourney = async () => {
    setCreateInProgress(true);
    await createJourney(history);
    setIsCreatingJourney(false);
    setCreateInProgress(false);
  };

  if (!newJourneyType) return null;

  return (
    <div className='w-full'>
      <Helmet>
        <title>Journey | Templates</title>
      </Helmet>
      <div className='flex flex-row w-full h-full bg-bedrock-black'>
        <div className='flex flex-col w-[372px] bg-bedrock-black p-6 space-y-6 h-screen'>
          <div className='flex flex-col justify-start space-y-6 text-white mt-2'>
            <IconButton onClick={history.goBack}>
              <CloseStrongIcon />
            </IconButton>
            <div className='text-neue-journey-medium-strong text-white'>Pick a template</div>
          </div>
          <TemplateList categories={categories} onUseTemplate={onCreateJourney} createInProgress={createInProgress} />
        </div>
        <div className='flex-grow flex-shrink-0 my-auto h-screen bg-bedrock-dark-gray'>
          <TemplatePlayer />
        </div>
      </div>
    </div>
  );
};

export default PageNewJourneyTemplate;
